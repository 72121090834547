export const ChevronUpIcon = () => (
  <svg
    width="14"
    height="6"
    viewBox="0 0 14 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7281 5.79438C14.0904 5.52022 14.0904 5.07571 13.7281 4.80155L7.68425 0.227573C7.67523 0.220142 7.66597 0.212823 7.65645 0.205621C7.47516 0.0684242 7.23751 -0.000116348 6.99991 0C6.7623 -0.000116348 6.52465 0.0684242 6.34336 0.205621C6.33384 0.212823 6.32458 0.220142 6.31556 0.227572L0.271693 4.80155C-0.0905733 5.07571 -0.0905733 5.52022 0.271692 5.79438C0.633959 6.06854 1.22131 6.06854 1.58358 5.79438L6.99991 1.69532L12.4162 5.79438C12.7785 6.06854 13.3659 6.06854 13.7281 5.79438Z"
      fill="#5D6293"
    />
  </svg>
);
