import { IChevron } from "../../views/interfaces";

export const LeftChevron = ({ onClick }: IChevron) => (
  <svg
    onClick={onClick}
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.29438 0.232877C6.02022 -0.0776253 5.57571 -0.0776256 5.30155 0.232876L0.719129 5.4227C0.714582 5.42758 0.710079 5.43255 0.705621 5.4376C0.568495 5.5929 0.499955 5.79646 0.5 6.00001C0.499956 6.20356 0.568496 6.40712 0.705621 6.56242C0.710079 6.56747 0.714583 6.57244 0.71913 6.57732L5.30155 11.7671C5.57571 12.0776 6.02022 12.0776 6.29438 11.7671C6.56854 11.4566 6.56854 10.9532 6.29438 10.6427L2.19504 6.00001L6.29438 1.3573C6.56854 1.0468 6.56854 0.543378 6.29438 0.232877Z"
      fill="#02094F"
    />
  </svg>
);
