import { Divider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import classes from "./MonthlyOccupancyVsMarket.module.scss";
import { MONTHLY_OCCUPANCY_RATE_REQUEST } from "../../../../../redux/actions";
import {
  yearlyMarketOccupancySelector,
  yearlyOccupancySelector,
} from "../../../../../redux/selectors/occupancyRateSelector";
import {
  getCurrentYearString,
  toDoublePercent,
} from "../../../../../utils/commonUtils";
import {
  shortMonthsNames,
  yearsList,
} from "../../../../../utils/constants/periodLists";
import { Legend } from "../../../../common/ChartLegend/ChartLegend";
import { PeriodDropdown } from "../../../../common/PeriodDropdown/PeriodDropdown";
import { IYearlyOccupancy } from "../../../../interfaces";
import { isMobileModeSelector } from "../../../../../redux/selectors/appStatusSelector";

const CustomTooltip = (props: any) => {
  const {
    active,
    payload,
    viewBox,
    isMobileView,
    cartesianGridBottomOffset,
    tooltipHeight,
    barWidth,
    arrowWidth,
    cartesianGridWidth,
    occupancyRateTooltipWidth,
    data,
    occupancyRateTooltipRef
  } = props;
  const leftMonths = isMobileView ? ['Jan', 'Feb', 'Mar'] : ['Jan'];
  const rightMonths = isMobileView ? ['Oct', 'Nov', 'Dec'] : ['Nov', 'Dec'];

  if (active && payload && payload.length) {
    const leftDataPoint = payload[0].payload;
    const rightDataPoint = payload[1].payload;
    const leftName = payload[0].name;
    const rightName = payload[1].name;
    const isLeftMonth = leftMonths.includes(leftDataPoint.name);
    const isRightMonth = rightMonths.includes(rightDataPoint.name);

    const dataPointDifference =
      (Math.abs(leftDataPoint[leftName] - rightDataPoint[rightName]) *
        viewBox?.height) /
      100;

    const isTooltipsOnDifferentSides = dataPointDifference < tooltipHeight;

    return (
      <>
        <div
          className={`${classes.tooltip} ${!isRightMonth ? classes.rightTooltip : classes.leftTooltip}`}
          style={{
            bottom:
              (viewBox?.height * rightDataPoint[rightName]) / 100 -
              cartesianGridBottomOffset + (!isMobileView ? barWidth / 2 : 0) +
              3 + (isMobileView ? tooltipHeight : 0) - (isMobileView ? barWidth - arrowWidth * 2 : 0),
            left: !isRightMonth
              ? (cartesianGridWidth / data?.length / 2 + arrowWidth + 2)
              : (-occupancyRateTooltipWidth + barWidth - cartesianGridWidth / data?.length / 2 + arrowWidth + 2),
          }}
        >
          <div className={`${classes.box} ${classes.yellow}`}></div>
          <p>{`${rightDataPoint[rightName]}%`}</p>
        </div>

        <div
          className={`${classes.tooltip} ${isMobileView
            ? (isTooltipsOnDifferentSides
              ? isLeftMonth ? classes.leftTooltip : classes.rightTooltip
              : !isRightMonth ? classes.rightTooltip : classes.leftTooltip)
            : isTooltipsOnDifferentSides
              ? classes.leftTooltip
              : classes.rightTooltip
          }`}
          style={{
            bottom:
              (viewBox?.height * rightDataPoint[rightName]) / 100 -
              cartesianGridBottomOffset +
              3 + (!isMobileView ? barWidth + arrowWidth + 3 : 0) -
              (isMobileView ? tooltipHeight / 2 - barWidth : 0),
            left: !isRightMonth
            ? (isTooltipsOnDifferentSides
              ? -occupancyRateTooltipWidth -
                arrowWidth +
                (cartesianGridWidth / data?.length - barWidth) / 2
              : (cartesianGridWidth / data?.length - barWidth) / 2 +
                barWidth +
                arrowWidth)
            : (isTooltipsOnDifferentSides
              ? -occupancyRateTooltipWidth -
                arrowWidth +
                (cartesianGridWidth / data?.length - barWidth) / 2
              : (-occupancyRateTooltipWidth - cartesianGridWidth / data?.length - barWidth) / 2 -
                arrowWidth),
          }}
          ref={occupancyRateTooltipRef}
        >
          <div className={`${classes.box} ${classes.green}`}></div>
          <p>{`${leftDataPoint[leftName]}%`}</p>
        </div>
      </>
    );
  }

  return null;
};

const CustomYAxisTick = (props: any) => {
  const { x, y, payload, isMobileView } = props;
  return (
    <g transform={`translate(${isMobileView ? 0 : 20},${y})`}>
      <text x={0} y={0} textAnchor="start" fontSize={12} fill="#9798A5">
        {payload.value === 0 ? payload.value : `${payload.value}%`}
      </text>
    </g>
  );
};

export const MonthlyOccupancyVsMarket = () => {
  const dispatch = useDispatch();
  const [year, setYear] = useState(getCurrentYearString());

  const generalData = useSelector(yearlyOccupancySelector(year));
  const marketData = useSelector(yearlyMarketOccupancySelector(year));
  const isMobileView = useSelector(isMobileModeSelector);

  const arrowWidth = isMobileView ? 6 : 10;
  const tooltipHeight = isMobileView ? 24 : 32;

  const responsiveContainerRef = useRef<any>(null);
  const cartesianGridRef = useRef<any>(null);
  const occupancyRateTooltipRef = useRef<any>(null);

  const [activeTooltipIndex, setActiveTooltipIndex] = useState<
    number | undefined
  >();
  const [cartesianGridWidth, setCartesianGridWidth] = useState(0);
  const [cartesianGridHeight, setCartesianGridHeight] = useState(0);
  const [cartesianGridLeftOffset, setCartesianGridLeftOffset] = useState(0);
  const [cartesianGridBottomOffset, setCartesianGridBottomOffset] = useState(0);
  const [occupancyRateTooltipWidth, setOccupancyRateTooltipWidth] = useState(0);

  const years = yearsList();

  const barWidth = isMobileView ? 12 : 40;

  const occupancyChartLegend = [
    {
      label: "Occupancy Rate",
      color: "#00B48D",
      legendType: "circle",
    },
    {
      label: "Market",
      color: "#EDC255",
      legendType: "line",
    },
  ];

  useEffect(() => {
    dispatch({
      type: MONTHLY_OCCUPANCY_RATE_REQUEST,
      payload: { year },
    });
  }, [dispatch, year]);

  const data =
    generalData && marketData
      ? generalData.map(
          ({ occupancy_rate }: IYearlyOccupancy, index: number) => {
            return {
              name: shortMonthsNames[index],
              occupancyRate: toDoublePercent(occupancy_rate),
              marketOccupancyRate: toDoublePercent(
                marketData[index].market_occupancy_rate
              ),
            };
          }
        )
      : [];

  const tooltipOffset =
    cartesianGridLeftOffset +
    cartesianGridWidth / data?.length / 2 +
    arrowWidth +
    2;

  useEffect(() => {
    const responsiveContainer = responsiveContainerRef?.current?.current;
    const cartesianGrid =
      cartesianGridRef?.current?._reactInternals?.child?.stateNode;
    const occupancyRateTooltip = occupancyRateTooltipRef?.current;

    cartesianGrid &&
      responsiveContainer &&
      setCartesianGridLeftOffset(
        cartesianGrid.getBoundingClientRect().left -
          responsiveContainer.getBoundingClientRect().left
      );
    cartesianGrid &&
      responsiveContainer &&
      setCartesianGridBottomOffset(
        responsiveContainer.getBoundingClientRect().bottom -
          cartesianGrid.getBoundingClientRect().bottom
      );

    cartesianGrid &&
      setCartesianGridWidth(cartesianGrid.getBoundingClientRect().width);
    cartesianGrid &&
      setCartesianGridHeight(cartesianGrid.getBoundingClientRect().height);
    occupancyRateTooltip &&
      setOccupancyRateTooltipWidth(
        occupancyRateTooltip.getBoundingClientRect().width
      );
  }, [activeTooltipIndex]);

  return (
    <div className={classes.occupancyChartContainer}>
      <div className={classes.occupancyChartHeader}>
        <div className={classes.occupancyLegendContainer}>
          <h4>
            {isMobileView
              ? "Monthly OR vs Market"
              : "Monthly Occupancy Rate vs Market:"}
          </h4>
          {!isMobileView ? <Divider flexItem orientation="vertical" /> : null}
          {!isMobileView ? <Legend legendData={occupancyChartLegend} /> : null}
        </div>
        <PeriodDropdown
          period={year}
          periodList={years}
          setPeriod={setYear}
          variant="standard"
        />
      </div>
      <ResponsiveContainer
        width="100%"
        height="80%"
        ref={responsiveContainerRef}
      >
        <ComposedChart
          width={600}
          height={300}
          data={data}
          margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          onMouseMove={({ activeTooltipIndex }) =>
            setActiveTooltipIndex(activeTooltipIndex)
          }
          onMouseLeave={() => setActiveTooltipIndex(undefined)}
        >
          <CartesianGrid
            stroke="#B4B7CF"
            strokeDasharray="2.33 4.66"
            strokeWidth="0.5"
            vertical={false}
            ref={cartesianGridRef}
          />
          <XAxis
            axisLine={false}
            dataKey="name"
            tickLine={false}
            type="category"
            interval={0}
            tick={{ fill: "#9798A5", fontSize: isMobileView ? 10 : 14 }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            type="number"
            width={isMobileView ? 24 : 60}
            unit="%"
            tick={<CustomYAxisTick isMobileView={isMobileView} />}
          />
          <Tooltip
            position={{
              x: activeTooltipIndex
                ? cartesianGridLeftOffset +
                  (activeTooltipIndex * cartesianGridWidth) / data.length
                : cartesianGridLeftOffset,
              y: cartesianGridHeight - 11 - (isMobileView ? 4 : 0),
            }}
            content={<CustomTooltip
              isMobileView={isMobileView}
              cartesianGridBottomOffset={cartesianGridBottomOffset}
              tooltipHeight={tooltipHeight}
              barWidth={barWidth}
              arrowWidth={arrowWidth}
              cartesianGridWidth={cartesianGridWidth}
              occupancyRateTooltipWidth={occupancyRateTooltipWidth}
              data={data}
              occupancyRateTooltipRef={occupancyRateTooltipRef}
            />}
            cursor={false}
          />
          <Bar
            barSize={barWidth}
            dataKey="occupancyRate"
            fill="#00B48D"
            isAnimationActive={false}
            radius={[4, 4, 0, 0]}
          />
          <Line
            dataKey="marketOccupancyRate"
            isAnimationActive={false}
            stroke="#EDC255"
            strokeWidth={3}
            type="monotone"
            filter="drop-shadow(0px 7px 5px rgba(93, 98, 147, 0.43))"
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
      {isMobileView ? <Legend legendData={occupancyChartLegend} /> : null}
    </div>
  );
};
