import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { DynamicIndicator } from '../../../views/common/DynamicIndicator/DynamicIndicator';
import { IOccupancyHighlight } from "../../interfaces";
import classes from "./CircleOccupancyHighlight.module.scss";

export const OccupancyHighlight = ({ label, rate, previousRate, reverse = false, showIndicator = false, highlightColor = '#00B48D' }: IOccupancyHighlight) => {
  return (
    <div className={classes.hightlightWrapper}>
      {reverse ? label && <h3>{label}</h3> : null}
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: "#F5F5FC",
            '& circle': {
              strokeLinecap: 'round',
            },
          }}
          size={95}
          thickness={5}
          value={100}
        />
        <CircularProgress
          thickness={5}
          size={94}
          value={rate >= 1 ? 100 : 100 * rate}
          variant="determinate"
          sx={{
            color: highlightColor,
            left: 0,
            position: "absolute",
            '& circle': {
              strokeLinecap: 'round',
            },
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <h4>{`${(rate >= 1 ? 100 : 100 * rate).toFixed(1)}%`}</h4>
        </Box>
      </Box>
      <div className="BasicHighlight_highlight__uAWSB">
        {showIndicator && <DynamicIndicator previous={previousRate} current={rate} />}
        {!reverse ? label && <h3 className="BasicHighlight_highlightLabel__ZvS5f">{label}</h3> : null}
      </div>
    </div>
  );
};
