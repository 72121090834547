import { L12M, L30D, N30D } from "../../utils/constants/filterConstants";
import { updateSeveralEntities } from "../../utils/reduxUtils";
import { IReduxAction } from "../../views/interfaces";
import { PERFOMANCE_HIGHLIGHTS_SUCCESS } from "../actions";

const initialState = {
  [L30D]: null,
  [L12M]: null,
  [N30D]: null,
};

export const perfomanceReducer = (state = initialState, { type, payload }: IReduxAction) => {
  switch (type) {
    case PERFOMANCE_HIGHLIGHTS_SUCCESS: {
      return updateSeveralEntities(state, payload);
    }
    default:
      return state;
  }
};
