import classes from "./RevenuePage.module.scss";
import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import { ReservationsByChannelPie } from "./components/ReservationsByChannelPie/ReservationsByChannelPie";
import { RevenueByMonth } from "./components/RevenueByMonth/RevenueByMonth";
import { RevenuePerNightCard } from "./components/RevenueCards/RevenuePerNight/RevenuePerNightCard";
import { TotalRevenueCard } from "./components/RevenueCards/TotalRevenue/TotalRevenueCard";
import { RevenueTable } from "./components/RevenueTable/RevenueTable";
import {useSelector} from "react-redux";
import {isMobileModeSelector} from "../../../redux/selectors/appStatusSelector";

export const RevenuePage = () => {
  const isMobileView = useSelector(isMobileModeSelector);
  return (
    <FullPageContainer>
      <>
      {isMobileView ? (
        <>
          <TotalRevenueCard />
          <RevenuePerNightCard />
          <RevenueByMonth />
          <ReservationsByChannelPie />
          <RevenueTable />
        </>
      ) : (
        <>
          <div className={classes.block}>
            <div className={classes.cards}>
              <TotalRevenueCard />
              <RevenuePerNightCard />
            </div>
            <RevenueByMonth />
          </div>
          <div className={classes.block}>
            <RevenueTable />
            <ReservationsByChannelPie />
          </div>
        </>
      )}
      </>
    </FullPageContainer>
  );
};
