import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, IconButton } from "@mui/material";
import classes from "./OccupancyRateBarLineCharts.module.scss";
import { OCCUPANCY_BY_YEAR_REQUEST } from "../../../redux/actions";
import { yearlyOccupancySelector } from "../../../redux/selectors/occupancyRateSelector";
import { getCurrentYearString, getPreviousYearString, toDoublePercent } from "../../../utils/commonUtils";
import { shortMonthsNames, yearsList } from "../../../utils/constants/periodLists";
import { IYearlyOccupancy } from "../../interfaces";
import { Legend } from "../ChartLegend/ChartLegend";
import { PeriodDropdown } from "../PeriodDropdown/PeriodDropdown";
import { OccupancyRateBar } from "./components/OccupancyRateBar/OccupancyRateBar";
import { OccupancyRateLineChart } from "./components/OccupancyRateLineChart/OccupancyRateLineChart";
import { BarIcon } from "../../../assets/icons/BarIcon";
import { LineIcon } from "../../../assets/icons/LineIcon";
import {isMobileModeSelector} from "../../../redux/selectors/appStatusSelector";

export const OccupancyRateBarLineCharts = (props: { bothCharts: boolean, page: 'performance' | 'occupancy' }) => {
  const dispatch = useDispatch();
  const { bothCharts, page } = props;
  const [fromYear, setFromYear] = useState(getPreviousYearString());
  const [toYear, setToYear] = useState(getCurrentYearString());
  const [chartType, setChartType] = useState("bar");

  const isMobileView = useSelector(isMobileModeSelector);

  const isBarActive = chartType === "bar";

  const fromYearData = useSelector(yearlyOccupancySelector(fromYear));
  const toYearData = useSelector(yearlyOccupancySelector(toYear));

  const years = yearsList();

  const occupancyBarLegend = [
    {
      label: fromYear,
      color: "#00B48D",
      legendType: "circle"
    },
    {
      label: toYear,
      color: "#5D6293",
      legendType: "circle"
    }
  ];

  const data =
    fromYearData && toYearData
      ? fromYearData.map(
          ({ occupancy_rate }: IYearlyOccupancy, index: number) => {
            return {
              name: shortMonthsNames[index],
              [fromYear]: toDoublePercent(occupancy_rate),
              [toYear]: toDoublePercent(toYearData[index].occupancy_rate)
            };
          }
        )
      : null;

  useEffect(() => {
    if (fromYear !== toYear) {
      dispatch({
        type: OCCUPANCY_BY_YEAR_REQUEST,
        payload: { year: fromYear }
      });
      dispatch({
        type: OCCUPANCY_BY_YEAR_REQUEST,
        payload: { year: toYear }
      });
    }
  }, [dispatch, fromYear, toYear]);

  return (
    <div
      className={
        bothCharts
          ? classes.occupancyChartsContainer
          : `${classes.occupancyChartsContainer} ${classes.occupancyBarContainer}`
      }
    >
      <div className={classes.occupancyChartsHeader}>
        <h4>{isMobileView ? "Occupancy Rate" : "Occupancy Rate:"}</h4>
        <div className={classes.periodBlock}>
          <PeriodDropdown
            className={classes.standardDropdown}
            period={fromYear}
            periodList={years}
            setPeriod={setFromYear}
            variant="standard"
          />
          <h4 className={classes.centerSelfAlign}> vs. </h4>
          <PeriodDropdown
            className={classes.standardDropdown}
            period={toYear}
            periodList={years}
            setPeriod={setToYear}
            variant="standard"
          />
        </div>
        {!isMobileView ? <Divider flexItem orientation="vertical" /> : null}
        {!isMobileView ? <div className={classes.legendRow}>
          <Legend legendData={occupancyBarLegend} />
          {bothCharts && (
            <div className={classes.switcher}>
              <IconButton
                classes={{ root: classes.button }}
                className={isBarActive ? classes.activeButton : ""}
                color={isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("bar")}
              >
                <BarIcon />
              </IconButton>
              <IconButton
                classes={{ root: classes.button }}
                className={!isBarActive ? classes.activeButton : ""}
                color={!isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("line")}
              >
                <LineIcon />
              </IconButton>
            </div>
          )}
        </div> : null}
      </div>
      {isBarActive && (
        <OccupancyRateBar data={data} fromYear={fromYear} toYear={toYear} page={page} />
      )}
      {bothCharts && !isBarActive && (
        <OccupancyRateLineChart
          data={data}
          fromYear={fromYear}
          toYear={toYear}
          page={page}
        />
      )}
      {isMobileView ? <div className={classes.legendRow}>
        <Legend legendData={occupancyBarLegend} />
        {bothCharts && (
          <div className={classes.switcher}>
            <IconButton
              classes={{ root: classes.button }}
              className={isBarActive ? classes.activeButton : ""}
              color={isBarActive ? "secondary" : "inherit"}
              aria-label="change chart type"
              onClick={() => setChartType("bar")}
            >
              <BarIcon />
            </IconButton>
            <IconButton
              classes={{ root: classes.button }}
              className={!isBarActive ? classes.activeButton : ""}
              color={!isBarActive ? "secondary" : "inherit"}
              aria-label="change chart type"
              onClick={() => setChartType("line")}
            >
              <LineIcon />
            </IconButton>
          </div>
        )}
      </div> : null}
    </div>
  );
};
