import classes from "./RenderYear.module.scss";
import { ICalendarYearView } from "../../../../../../interfaces";
import { RenderMonth } from "../RenderMonth/RenderMonth";
import { longMonthsNames } from "../../../../../../../utils/constants/periodLists";
import { Legend } from "../../../../../../common/ChartLegend/ChartLegend";
import { useSelector } from "react-redux";
import { isMobileModeSelector } from "../../../../../../../redux/selectors/appStatusSelector";
import { calendarLegend } from "../../Calendar";
import { SHOW_MONTH } from "../../../../../../../utils/constants/commonConstants";

export const RenderYear = ({
  calendarData,
  currentYear,
  reservationsData,
  setCurrentDate,
  setMode,
}: ICalendarYearView) => {
  const isMobileView = useSelector(isMobileModeSelector);
  return (
    <>
      <div className={classes.calendarYear}>
        {calendarData.map((month: any, index: number) => {
          return (
            <div className={classes.month} key={`month_${index}`}>
              <p
                className={`${classes.monthName} ${
                  new Date().getMonth() === index && classes.current
                }`}
                onClick={() => {
                  setCurrentDate(new Date(currentYear, index, 1));
                  setMode(SHOW_MONTH);
                }}
              >
                {longMonthsNames[index]}
              </p>
              <RenderMonth
                calendarData={month}
                currentMonth={index}
                currentYear={currentYear}
                reservationsData={reservationsData}
                small={true}
                year={true}
                onClick={() => {
                  setCurrentDate(new Date(currentYear, index, 1));
                  setMode(SHOW_MONTH);
                }}
                isLeftSide={false}
              />
            </div>
          );
        })}
      </div>
      {isMobileView && <Legend legendData={calendarLegend} />}
    </>
  );
};
