import classes from "./BigCalendar.module.scss";
import { Calendar } from "../Calendar/Calendar";

interface ICalendar {
  reservationsData: any;
}

export const BigCalendar = ({reservationsData}: ICalendar) => {
  return (
    <Calendar
      className={classes.calendarContainer}
      onlyOneMonth={false}
      reservationsData={reservationsData}
      isLeftSide={false}
    />
  );
};
