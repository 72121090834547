import { all, call, put, takeLatest } from "redux-saga/effects";
import { ReservationsAPI } from "../../../api/reservationsAPI";
import { processReservationsData } from "../../../utils/dataProcessingUtils";
import {
  GET_RESERVATIONS_DATA_REQUEST,
  GET_RESERVATIONS_DATA_SUCCESS,
  GET_RESERVATIONS_DATA_ERROR
} from "../../actions";

function* workerReservationsData(): Generator<any> {
  try {
    const data: any = yield all([
      call(ReservationsAPI.getGuestReservations),
      call(ReservationsAPI.getOwnerReservations)
    ]);

    const flattenedData = data.flat().filter(Boolean);

    yield put({
      type: GET_RESERVATIONS_DATA_SUCCESS,
      payload: {
        reservations: flattenedData,
        reservationsMap: processReservationsData(flattenedData)
      }
    });
  } catch (error: any) {
    yield put({
      type: GET_RESERVATIONS_DATA_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherReservationsData() {
  yield takeLatest(GET_RESERVATIONS_DATA_REQUEST, workerReservationsData);
}
