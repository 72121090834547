import { getServiceURL, requestHandler } from "../utils/apiUtils";
import { L12M } from "../utils/constants/filterConstants";

export class CommonAPI {
  static get getUniqueReservationsServiceName() {
    return "GET_UNIQUE_RESERVATIONS";
  }

  static get getGuestsHostedServiceName() {
    return "GET_HOSTED_GUESTS";
  }

  static get getTotalBookedNightsServiceName() {
    return "GET_NUMBER_OF_BOOKED_NIGHTS";
  }

  static get getNetReservationIncomeServiceName() {
    return "GET_NET_RESERVATION_INCOME";
  }

  static async getUniqueReservations(params?: {
    param?: string;
  }): Promise<object[]> {
    const url = getServiceURL(CommonAPI.getUniqueReservationsServiceName);
    const response = await requestHandler(url, params);
    return response?.data;
  }

  static async getGuestsHosted(params?: { param?: string }): Promise<object> {
    const url = getServiceURL(CommonAPI.getGuestsHostedServiceName);
    const response = await requestHandler(url, params || { param: L12M });
    return response?.data;
  }

  static async getTotalBookedNights(params?: {
    param?: string;
  }): Promise<number> {
    const url = getServiceURL(CommonAPI.getTotalBookedNightsServiceName);
    const response = await requestHandler(url, params || { param: L12M });
    return response?.data;
  }

  static async getNetReservationIncome(params?: {
    param?: string;
  }): Promise<number> {
    const url = getServiceURL(CommonAPI.getNetReservationIncomeServiceName);
    const response = await requestHandler(url, params);
    return response?.data;
  }
}
