import { all, call, put, takeLatest } from "redux-saga/effects";
import { RevenueAPI } from "../../../api/revenueAPI";
import {
  GET_PER_NIGHT_REVENUE_ERROR,
  GET_PER_NIGHT_REVENUE_REQUEST,
  GET_PER_NIGHT_REVENUE_SUCCESS
} from "../../actions";

function* workerPerNightRevenue(action: {
  type: string;
  payload: { param: string };
}): Generator<any> {
  try {
    const params = action.payload;
    const perNightData: any = yield all([
      call(RevenueAPI.getPerNightRevenue, params),
      call(RevenueAPI.getPerNightMarketRevenue, params),
    ]);
    const [general, market] = perNightData;
    yield put({
      type: GET_PER_NIGHT_REVENUE_SUCCESS,
      payload: {
        perNightRevenue: {
          [params.param]: { general, market }
        }
      }
    });
  } catch (error: any) {
    yield put({
      type: GET_PER_NIGHT_REVENUE_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherPerNightRevenue() {
  yield takeLatest(GET_PER_NIGHT_REVENUE_REQUEST, workerPerNightRevenue);
}
