export const BookingIcon = () => (
  <svg
    width="27"
    height="28"
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1853_16008)">
      <path
        d="M9.88831 23.2888H5.28279V17.6648C5.28279 16.4662 5.73431 15.8209 6.74572 15.6918H9.90637C10.4038 15.6497 10.9042 15.7189 11.3727 15.8946C11.8413 16.0703 12.2666 16.3482 12.6191 16.709C12.9716 17.0698 13.2428 17.5047 13.4137 17.9835C13.5847 18.4622 13.6513 18.9733 13.6088 19.4811C13.6088 21.8782 12.1911 23.3072 9.90637 23.3072L9.88831 23.2888ZM5.27376 8.08561V6.61047C5.27376 5.31972 5.81559 4.69279 6.98954 4.61903H9.35551C11.3783 4.61903 12.6065 5.85446 12.6065 7.9381C12.6065 9.50543 11.7757 11.3494 9.44582 11.3494H5.29182L5.27376 8.08561ZM15.7852 13.7096L14.9544 13.2302L15.6768 12.5848C16.5257 11.8472 17.9344 10.1692 17.9344 7.2835C17.9344 2.85808 14.5751 0 9.3826 0H2.7904C2.046 0.0261108 1.34023 0.344666 0.820328 0.889206C0.300423 1.43375 0.00654362 2.16221 0 2.92262V28H9.5C15.2795 28 19 24.7916 19 19.813C19 17.1393 17.799 14.8344 15.7671 13.6819"
        fill="#5D6293"
      />
      <path
        d="M21 25C21 23.3406 22.3361 22 23.9918 22C25.6475 22 27 23.3406 27 25C27 26.6594 25.6557 28 23.9918 28C22.3279 28 21 26.6594 21 25Z"
        fill="#5D6293"
      />
    </g>
    <defs>
      <clipPath id="clip0_1853_16008">
        <rect width="27" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
