import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import classes from "./RevenueByMonth.module.scss";
import { GET_REVENUE_BY_MONTH_REQUEST } from "../../../../../redux/actions";
import {
  shortMonthsNames,
  yearsList,
} from "../../../../../utils/constants/periodLists";
import { Legend } from "../../../../common/ChartLegend/ChartLegend";
import { PeriodDropdown } from "../../../../common/PeriodDropdown/PeriodDropdown";
import { revenueByMonthSelector } from "../../../../../redux/selectors/revenueSelector";
import { IYearlyRevenue } from "../../../../interfaces";
import {
  findMaxNumericValue,
  getCurrentYearString,
  getPreviousYearString,
} from "../../../../../utils/commonUtils";
import { isMobileModeSelector } from "../../../../../redux/selectors/appStatusSelector";
import { IconButton } from "@mui/material";
import { BarIcon } from "../../../../../assets/icons/BarIcon";
import { LineIcon } from "../../../../../assets/icons/LineIcon";
import { RevenueByMonthBarChart } from "./components/RevenueByMonthBarChart/RevenueByMonthBarChart";
import { RevenueByMonthLineChart } from "./components/RevenueByMonthLineChart/RevenueByMonthLineChart";

export const RevenueByMonth = () => {
  const dispatch = useDispatch();
  const [fromYear, setFromYear] = useState(getPreviousYearString());
  const [toYear, setToYear] = useState(getCurrentYearString());
  const [chartType, setChartType] = useState("bar");
  const [maxRevenueValue, setMaxRevenueValue] = useState(0)
  const isBarActive = chartType === "bar";

  const fromYearData = useSelector(revenueByMonthSelector(fromYear));
  const toYearData = useSelector(revenueByMonthSelector(toYear));
  const isMobileView = useSelector(isMobileModeSelector);

  const years = yearsList();

  const revenueBarLegend = [
    {
      label: fromYear,
      color: "#00B48D",
      legendType: "circle",
    },
    {
      label: toYear,
      color: "#5D6293",
      legendType: "circle",
    },
  ];

  const barRadius = isMobileView ? 2 : 4;
  const barWidth = isMobileView ? 6 : 8;
  const barGap = isMobileView ? 2 : 4;

  const data =
    fromYearData && toYearData
      ? fromYearData.map(({ Revenue }: IYearlyRevenue, index: number) => {
          return {
            name: shortMonthsNames[index],
            [fromYear]: Revenue.toFixed(1),
            [toYear]: toYearData[index].Revenue.toFixed(1),
          };
        })
      : null;

useEffect(() => {
  if (data) {
    setMaxRevenueValue(findMaxNumericValue(data))
  }
}, [data])

  useEffect(() => {
    if (fromYear !== toYear) {
      dispatch({
        type: GET_REVENUE_BY_MONTH_REQUEST,
        payload: { year: fromYear },
      });
      dispatch({
        type: GET_REVENUE_BY_MONTH_REQUEST,
        payload: { year: toYear },
      });
    }
  }, [dispatch, fromYear, toYear]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.revenueBarContainer}>
        <div className={classes.revenueBarHeader}>
          <div className={classes.revenueBarHeaderLeft}>
            <h4>Revenue</h4>
            <div className={classes.periodBlock}>
              <PeriodDropdown
                period={fromYear}
                periodList={years}
                setPeriod={setFromYear}
                variant="standard"
              />
              <h4 className={classes.centerSelfAlign}> vs. </h4>
              <PeriodDropdown
                period={toYear}
                periodList={years}
                setPeriod={setToYear}
                variant="standard"
              />
            </div>
          </div>
          {!isMobileView && (
            <div className={classes.switcher}>
              <IconButton
                classes={{ root: classes.button }}
                className={isBarActive ? classes.activeButton : ""}
                color={isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("bar")}
              >
                <BarIcon />
              </IconButton>
              <IconButton
                classes={{ root: classes.button }}
                className={!isBarActive ? classes.activeButton : ""}
                color={!isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("line")}
              >
                <LineIcon />
              </IconButton>
            </div>
          )}
        </div>
        {isBarActive ? (
          data && <RevenueByMonthBarChart
            data={data}
            fromYear={fromYear}
            toYear={toYear}
            maxRevenueValue={maxRevenueValue}
          />
        ) : (
          data && <RevenueByMonthLineChart
            data={data}
            fromYear={fromYear}
            toYear={toYear}
            maxRevenueValue={maxRevenueValue}
          />
        )}
        <div className={classes.legendRow}>
          <Legend legendData={revenueBarLegend} />
          {isMobileView && (
            <div className={classes.switcher}>
              <IconButton
                classes={{ root: classes.button }}
                className={isBarActive ? classes.activeButton : ""}
                color={isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("bar")}
              >
                <BarIcon />
              </IconButton>
              <IconButton
                classes={{ root: classes.button }}
                className={!isBarActive ? classes.activeButton : ""}
                color={!isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("line")}
              >
                <LineIcon />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
