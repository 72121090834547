import { IIcon } from "../../views/interfaces";

export const MailIcon = ({ className }: IIcon) => (
  <svg
    className={className}
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.4 20C1.74 20 1.1752 19.7554 0.7056 19.2663C0.2352 18.7763 0 18.1875 0 17.5V2.5C0 1.8125 0.2352 1.22417 0.7056 0.735C1.1752 0.245 1.74 0 2.4 0H21.6C22.26 0 22.8252 0.245 23.2956 0.735C23.7652 1.22417 24 1.8125 24 2.5V17.5C24 18.1875 23.7652 18.7763 23.2956 19.2663C22.8252 19.7554 22.26 20 21.6 20H2.4ZM12 11.0312C12.1 11.0312 12.2048 11.0154 12.3144 10.9837C12.4248 10.9529 12.53 10.9062 12.63 10.8438L21.12 5.3125C21.28 5.20833 21.4 5.07833 21.48 4.9225C21.56 4.76583 21.6 4.59375 21.6 4.40625C21.6 3.98958 21.43 3.67708 21.09 3.46875C20.75 3.26042 20.4 3.27083 20.04 3.5L12 8.75L3.96 3.5C3.6 3.27083 3.25 3.26542 2.91 3.48375C2.57 3.70292 2.4 4.01042 2.4 4.40625C2.4 4.61458 2.44 4.79667 2.52 4.9525C2.6 5.10917 2.72 5.22917 2.88 5.3125L11.37 10.8438C11.47 10.9062 11.5752 10.9529 11.6856 10.9837C11.7952 11.0154 11.9 11.0312 12 11.0312Z"
      fill="#5D6293"
    />
  </svg>
);
