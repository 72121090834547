import { call, put, takeLatest } from "redux-saga/effects";
import { ContactsAPI } from "../../../api/contactsAPI";
import { SEND_CONTACTS_ERROR, SEND_CONTACTS_REQUEST, SEND_CONTACTS_SUCCESS } from "../../actions";

function* workerSendContacts(action: {
  type: string;
  payload: { name: string; surname: string; email: string; message: string };
}): Generator<any> {
  try {
    yield call(ContactsAPI.sendContacts, action.payload);
    yield put({
      type: SEND_CONTACTS_SUCCESS,
    });
  } catch (error: any) {
    yield put({ type: SEND_CONTACTS_ERROR, payload: { message: error.message } });
  }
}

export function* watcherSendContacts() {
  yield takeLatest(SEND_CONTACTS_REQUEST, workerSendContacts);
}
