import { IIcon } from "../../views/interfaces";

export const TwitterIcon = ({ className }: IIcon) => (
  <svg
    className={className}
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 2.35294C23.1166 2.76471 22.1644 3.03529 21.1778 3.16471C22.1874 2.54118 22.9675 1.55294 23.3346 0.364706C22.3824 0.952941 21.327 1.36471 20.2141 1.6C19.3078 0.588235 18.0344 0 16.5889 0C13.8929 0 11.6902 2.25882 11.6902 5.04706C11.6902 5.44706 11.7361 5.83529 11.8164 6.2C7.73231 5.98824 4.0956 3.97647 1.67495 0.929412C1.25048 1.67059 1.00956 2.54118 1.00956 3.45882C1.00956 5.21176 1.86998 6.76471 3.20077 7.64706C2.38623 7.64706 1.62906 7.41177 0.963671 7.05882V7.09412C0.963671 9.54118 2.66157 11.5882 4.91013 12.0471C4.18822 12.2497 3.43032 12.2778 2.69599 12.1294C3.00758 13.1323 3.61782 14.0099 4.44094 14.6387C5.26405 15.2676 6.25864 15.6161 7.2849 15.6353C5.54527 17.0476 3.38891 17.811 1.17017 17.8C0.780115 17.8 0.390057 17.7765 0 17.7294C2.17973 19.1647 4.77247 20 7.54876 20C16.5889 20 21.5564 12.3059 21.5564 5.63529C21.5564 5.41177 21.5564 5.2 21.5449 4.97647C22.5086 4.27059 23.3346 3.37647 24 2.35294Z"
      fill="#B4B7CF"
    />
  </svg>
);
