import { apiInstance } from "./axiosConfig";
import services from "../services.json";

const servicesObj = JSON.parse(JSON.stringify(services));

export const getAuthToken = () => {
  const token = sessionStorage.getItem("AH_JWT_TOKEN");
  if (token === "undefined") {
    return undefined;
  }
  return token;
};

export const getServiceURL = (serviceName: string): string => {
  return servicesObj[serviceName];
};

const getErrorMessageFromResponse = (response: any) => {
  if (Array.isArray(response.data.message)) {
    return response.data.message[0];
  }

  if (typeof response.data.message === "string") {
    return response.data.message;
  }

  return response.data.error;
}

export async function requestHandler(url: string, params?: object) {
  try {
    const response: any = await makeRequest(url, params);

    if (response.data?.status === 200 || response.data.success) {
      return response;
    }

    if (response.data?.status === 404 && response.data?.message === 'Data not found!' ) {
      response.data = null;
      return response;
    }

    const errorMessage = getErrorMessageFromResponse(response);
    throw new Error(errorMessage);
  } catch (error: any) {
    if (error.response?.status === 401) {
      sessionStorage.setItem("AH_JWT_TOKEN", "undefined");
    } else {
     throw error;
    }
  }
}

async function makeRequest(url: string, params?: object) {
  const axiosInstance = apiInstance();
  if (params) {
    return await axiosInstance.post(url, params);
  } else {
    return await axiosInstance.get(url);
  }
}
