import { call, put, takeLatest } from "redux-saga/effects";
import { RevenueAPI } from "../../../api/revenueAPI";
import {
  GET_YEARLY_REVENUE_ERROR,
  GET_YEARLY_REVENUE_REQUEST,
  GET_YEARLY_REVENUE_SUCCESS
} from "../../actions";

function* workerYearlyRevenue(): Generator<any> {
  try {
    const revenueByYear: any = yield call(RevenueAPI.getYearlyRevenue);
    yield put({
      type: GET_YEARLY_REVENUE_SUCCESS,
      payload: {
        revenueByYear: revenueByYear.sort((a: any, b: any) => new Date(a.start_date).valueOf() - new Date(b.start_date).valueOf()),
      }
    });
  } catch (error: any) {
    yield put({
      type: GET_YEARLY_REVENUE_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherYearlyRevenue() {
  yield takeLatest(GET_YEARLY_REVENUE_REQUEST, workerYearlyRevenue);
}
