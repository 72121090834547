import { IChartLegend } from "../../interfaces";
import classes from "./ChartLegend.module.scss";

export const Legend = ({ className = "", legendData }: IChartLegend) => {
  return (
    <div className={`${classes.legendContainer} ${className}`}>
      {legendData && legendData.map(({ label, color, legendType }, index: number) => {
        const isCircle = legendType === "circle";
        return (
          <div className={classes.legend} key={index}>
            <span
              className={isCircle ? classes.circle : classes.line}
              style={{ background: color }}
            />
            <p className={classes.legendLabel}>{label}</p>
          </div>
        );
      })}
    </div>
  );
};
