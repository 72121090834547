import { requestHandler, getServiceURL } from "../utils/apiUtils";

export class PerfomanceAPI {
  static get getAvarageDailyRatesServiceName() {
    return "GET_AVERAGE_DAILY_RATES";
  }

  static async getAvarageDailyRates(params?: {
    param?: string;
  }): Promise<number> {
    const url = getServiceURL(PerfomanceAPI.getAvarageDailyRatesServiceName);
    const response = await requestHandler(url, params);
    return response?.data;
  }
}
