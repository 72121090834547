export const ChevronDownIcon = () => (
  <svg
    width="14"
    height="6"
    viewBox="0 0 14 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.271577 0.205621C-0.0906889 0.479783 -0.0906884 0.924288 0.271578 1.19845L6.31545 5.77243C6.32446 5.77986 6.33373 5.78718 6.34324 5.79438C6.52453 5.93158 6.76218 6.00012 6.99979 6C7.2374 6.00012 7.47505 5.93158 7.65634 5.79438C7.66585 5.78718 7.67512 5.77986 7.68413 5.77243L13.728 1.19845C14.0903 0.924288 14.0903 0.479783 13.728 0.205621C13.3657 -0.0685404 12.7784 -0.0685405 12.4161 0.205621L6.99979 4.30468L1.58346 0.205621C1.22119 -0.0685405 0.633844 -0.0685404 0.271577 0.205621Z"
      fill="#5D6293"
    />
  </svg>
);
