import classes from "./SmallCalendar.module.scss";
import { Calendar } from "../Calendar/Calendar";

interface ICalendar {
  reservationsData: any;
}

export default function SmallCalendar({reservationsData}: ICalendar) {
  return (
    <Calendar
      className={classes.calendarContainer}
      onlyOneMonth={true}
      reservationsData={reservationsData}
      isLeftSide={true}
    />
  );
}
