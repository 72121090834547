import { useState } from "react";
import { Button, ButtonGroup } from "@mui/material";
import classes from "./SwitchControl.module.scss";
import { isMobileModeSelector } from "../../../redux/selectors/appStatusSelector";
import { useSelector } from "react-redux";

interface ISwitchControl {
  left: string;
  right: string;
  active: string;
  handleSwitch: Function;
}

export const SwitchControl = ({
  left,
  right,
  active,
  handleSwitch,
}: ISwitchControl) => {
  // const [active, setActive] = useState(activeButton);
  const isMobile = useSelector(isMobileModeSelector);

  return (
    <ButtonGroup
      aria-label="Controls"
      className={classes.buttonGroup}
      disableElevation
      variant={isMobile ? "contained" : "outlined"}
    >
      <Button
        className={active === left ? classes.activeButton : classes.button}
        color="secondary"
        sx={{ border: "none " }}
        onClick={() => {
          // setActive(left);
          handleSwitch(left);
        }}
      >
        {left}
      </Button>
      <Button
        className={active === right ? classes.activeButton : classes.button}
        color="secondary"
        sx={{ border: "none " }}
        onClick={() => {
          // setActive(right);
          handleSwitch(right);
        }}
      >
        {right}
      </Button>
    </ButtonGroup>
  );
};
