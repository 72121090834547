import { IChevron } from "../../views/interfaces";

export const RightChevron = ({ onClick }: IChevron) => (
  <svg
    onClick={onClick}
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.205621 11.7671C0.479783 12.0776 0.924287 12.0776 1.19845 11.7671L5.77194 6.5872C5.77954 6.57932 5.78702 6.57121 5.79438 6.56287C5.93158 6.40748 6.00012 6.20377 6 6.0001C6.00012 5.79643 5.93158 5.59272 5.79438 5.43732C5.78702 5.42899 5.77954 5.42088 5.77194 5.41299L1.19845 0.23308C0.924287 -0.077434 0.479783 -0.0774331 0.205621 0.233081C-0.0685403 0.543595 -0.0685405 1.04704 0.205621 1.35755L4.30466 6.0001L0.205621 10.6426C-0.0685405 10.9532 -0.0685403 11.4566 0.205621 11.7671Z"
      fill="#02094F"
    />
  </svg>
);
