import { call, put, takeEvery } from "redux-saga/effects";
import { RevenueAPI } from "../../../api/revenueAPI";
import {
  GET_REVENUE_BY_MONTH_ERROR,
  GET_REVENUE_BY_MONTH_REQUEST,
  GET_REVENUE_BY_MONTH_SUCCESS
} from "../../actions";

function* workerRevenueByMonth(action: {
    type: string;
    payload: { year: string };
  }): Generator<any> {
  try {
    const year = action.payload.year;
    const revenueByMonth: any = yield call(RevenueAPI.getRevenueByMonth, action.payload);
    yield put({
      type: GET_REVENUE_BY_MONTH_SUCCESS,
      payload: {
        revenueByMonth: { [year]: revenueByMonth }
      }
    });
  } catch (error: any) {
    yield put({
      type: GET_REVENUE_BY_MONTH_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherRevenueByMonth() {
  yield takeEvery(GET_REVENUE_BY_MONTH_REQUEST, workerRevenueByMonth);
}
