import { yearsRange } from "../commonUtils";
import { L12M, L30D, N30D } from "./filterConstants";

export const periodList = [
  {
    id: L30D,
    label: L30D
  },
  {
    id: L12M,
    label: L12M
  },
  {
    id: N30D,
    label: N30D
  }
];

export const yearsList = () => {
  const years = yearsRange();
  return years.map((year: number) => {
    return {
      id: String(year),
      label: String(year)
    };
  });
};

export const shortMonthsNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const longMonthsNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const fullLengthDayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const weekDayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const shortWeekDayNames = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

export const oneLetterWeekDayNames = ["M", "T", "W", "T", "F", "S", "S"];

export const getDayName = (weekDayName: string) => fullLengthDayNames[weekDayNames.indexOf(weekDayName)] || ''
