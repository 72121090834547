import { getServiceURL, requestHandler } from "../utils/apiUtils";
import { L12M } from "../utils/constants/filterConstants";

export class ReservationsAPI {
  static get getGuestReservationsServiceName() {
    return "GET_GUEST_RESERVATIONS";
  }

  static get getOwnerReservationsServiceName() {
    return "GET_OWNER_RESERVATIONS";
  }

  static get getPaidReservationsServiceName() {
    return "GET_PAID_RESERVATIONS";
  }

  static get getSumPreviousNightsBookedServiceName() {
    return "GET_SUM_PREVIOUS_NIGHTS_BOOKED";
  }

  static get getSumUpcomingNightsBookedServiceName() {
    return "GET_SUM_UPCOMING_NIGHTS_BOOKED";
  }

  static get getCountPreviousReservationsServiceName() {
    return "GET_COUNT_PREVIOUS_RESERVATIONS";
  }

  static get getCountUpcomingReservationsServiceName() {
    return "GET_COUNT_UPCOMING_RESERVATIONS";
  }

  static get getSumPreviousGuestsHostedServiceName() {
    return "GET_SUM_PREVIOUS_GUESTS_HOSTED";
  }

  static get getSumUpcomingGuestsHostedServiceName() {
    return "GET_SUM_UPCOMING_GUESTS_HOSTED";
  }

  static get getCountPreviousOwnersBlocksServiceName() {
    return "GET_COUNT_PREVIOUS_OWNERS_BLOCKS";
  }

  static get getCountUpcomingOwnersBlocksServiceName() {
    return "GET_COUNT_UPCOMING_OWNERS_BLOCKS";
  }

  static get getOwnerBlocksServiceName() {
    return "GET_OWNER_BLOCKS";
  }

  static async getGuestReservations(): Promise<object> {
    const url = getServiceURL(ReservationsAPI.getGuestReservationsServiceName);
    const response = await requestHandler(url);
    return response?.data?.data;
  }

  static async getOwnerReservations(): Promise<object> {
    const url = getServiceURL(ReservationsAPI.getOwnerReservationsServiceName);
    const response = await requestHandler(url);
    return response?.data?.data;
  }

  static async getPaidReservations(): Promise<object> {
    const url = getServiceURL(ReservationsAPI.getPaidReservationsServiceName);
    const response = await requestHandler(url, { param: L12M });
    return response?.data?.TotalRecords;
  }

  static async getOwnerBlocks(): Promise<number> {
    const url = getServiceURL(ReservationsAPI.getOwnerBlocksServiceName);
    const response = await requestHandler(url, { param: L12M });
    return response?.data?.TotalRecords;
  }

  static async getSumPreviousNightsBooked(): Promise<number> {
    const url = getServiceURL(ReservationsAPI.getSumPreviousNightsBookedServiceName);
    const response = await requestHandler(url);
    return response?.data;
  }

  static async getSumUpcomingNightsBooked(): Promise<number> {
    const url = getServiceURL(ReservationsAPI.getSumUpcomingNightsBookedServiceName);
    const response = await requestHandler(url);
    return response?.data;
  }

  static async getCountPreviousReservations(): Promise<number> {
    const url = getServiceURL(ReservationsAPI.getCountPreviousReservationsServiceName);
    const response = await requestHandler(url);
    return response?.data;
  }

  static async getCountUpcomingReservations(): Promise<number> {
    const url = getServiceURL(ReservationsAPI.getCountUpcomingReservationsServiceName);
    const response = await requestHandler(url);
    return response?.data;
  }

  static async getSumPreviousGuestsHosted(): Promise<number> {
    const url = getServiceURL(ReservationsAPI.getSumPreviousGuestsHostedServiceName);
    const response = await requestHandler(url);
    return response?.data;
  }

  static async getSumUpcomingGuestsHosted(): Promise<number> {
    const url = getServiceURL(ReservationsAPI.getSumUpcomingGuestsHostedServiceName);
    const response = await requestHandler(url);
    return response?.data;
  }

  static async getCountPreviousOwnersBlocks(): Promise<number> {
    const url = getServiceURL(ReservationsAPI.getCountPreviousOwnersBlocksServiceName);
    const response = await requestHandler(url);
    return response?.data;
  }

  static async getCountUpcomingOwnersBlocks(): Promise<number> {
    const url = getServiceURL(ReservationsAPI.getCountUpcomingOwnersBlocksServiceName);
    const response = await requestHandler(url);
    return response?.data;
  }
}
