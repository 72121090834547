import { call, put, takeLatest } from "redux-saga/effects";
import { OccupancyRateAPI } from "../../../api/occupancyRateAPI";
import {
  MARKET_OCCUPANCY_ERROR,
  MARKET_OCCUPANCY_REQUEST,
  MARKET_OCCUPANCY_SUCCESS,
} from "../../actions";

function* workerMarketOccupancy(action: {
  type: string;
  payload: { param: string };
}): Generator<any> {
  try {
    const rate: any = yield call(OccupancyRateAPI.getMarketOccupancy, action.payload);
    yield put({
      type: MARKET_OCCUPANCY_SUCCESS,
      payload: { marketOccupancy: { [action.payload.param]: rate } }
    });
  } catch (error: any) {
    yield put({
      type: MARKET_OCCUPANCY_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherMarketOccupancy() {
  yield takeLatest(MARKET_OCCUPANCY_REQUEST, workerMarketOccupancy);
}
