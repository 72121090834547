import {useSelector} from "react-redux";

import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import { OccupancyRateBarLineCharts } from "../../common/OccupancyRateCharts/OccupancyRateBarLineCharts";
import { DailyOccupancyChart } from "./components/DailyOccupancyChart/DailyOccupancyChart";
import { MonthlyOccupancyVsMarket } from "./components/MonthlyOccupancyVsMarket/MonthlyOccupancyVsMarket";
import { OccupancyRatePie } from "./components/OccupancyRatePie/OccupancyRatePie";
import { OccupancyRateVsMarket } from "./components/OccupancyRateVsMarket/OccupancyRateVsMarket";
import classes from "./OccupancyPage.module.scss";
import {isMobileModeSelector} from "../../../redux/selectors/appStatusSelector";

export const OccupancyPage = () => {
  const isMobileView = useSelector(isMobileModeSelector);
  return <FullPageContainer className={classes.occupancyWrapper}>
    <>
      {isMobileView ? (
        <>
          <OccupancyRatePie />
          <OccupancyRateVsMarket />
          <DailyOccupancyChart />
          <OccupancyRateBarLineCharts page="occupancy" bothCharts />
          <MonthlyOccupancyVsMarket />
        </>
      ) : (
        <>
          <div className={classes.fullWidthBlock}>
            <OccupancyRatePie />
            <OccupancyRateBarLineCharts page="occupancy" bothCharts />
          </div>
          <div className={classes.fullWidthBlock}>
            <OccupancyRateVsMarket />
            <DailyOccupancyChart />
          </div>
          <MonthlyOccupancyVsMarket />
        </>
      )}
    </>
  </FullPageContainer>;
}
