import { call, put, takeEvery } from "redux-saga/effects";
import { OccupancyRateAPI } from "../../../api/occupancyRateAPI";
import {
  OCCUPANCY_BY_YEAR_ERROR,
  OCCUPANCY_BY_YEAR_REQUEST,
  OCCUPANCY_BY_YEAR_SUCCESS,
} from "../../actions";

function* workerYearlyOccupancy(action: {
  type: string;
  payload: { year: string };
}): Generator<any> {
  try {
    const year = action.payload.year;
    const data: any = yield call(OccupancyRateAPI.getYearlyOccupancyRate, action.payload);
    yield put({
      type: OCCUPANCY_BY_YEAR_SUCCESS,
      payload: { yearlyOccupancy: { [year]: data } }
    });
  } catch (error: any) {
    yield put({
      type: OCCUPANCY_BY_YEAR_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherYearlyOccupancy() {
  yield takeEvery(OCCUPANCY_BY_YEAR_REQUEST, workerYearlyOccupancy);
}