import { useCallback, useEffect, useRef, useState } from 'react';
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";

import CustomHeader from './components/CustomHeader/CustomHeader';
import CustomInput from './components/CustomInput/CustomInput';
import useClickOutside from '../../../../../../hooks/useClickOutside';
import "react-datepicker/dist/react-datepicker.css";
import "./SelectDates.styles.scss";
import classes from "./SelectDates.module.scss";
import { addMonths, subtractMonths, getDisplayDate } from "../../../../../../utils/dateUtils";
import {isMobileModeSelector} from "../../../../../../redux/selectors/appStatusSelector";

interface ISelectDates {
  filterTable: Function;
}

export const SelectDates = ({ filterTable }: ISelectDates) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null | undefined>(null);
  const [endDate, setEndDate] = useState<Date | null | undefined>(null);

  const [openToStartDate, setOpenToStartDate] = useState<Date | null>(new Date());
  const [openToEndDate, setOpenToEndDate] = useState<Date | null>(addMonths(new Date(), 1) as Date);
  const [minYear, setMinYear] = useState((openToStartDate as Date).getFullYear());

  const isMobileView = useSelector(isMobileModeSelector);

  const calendarRef = useRef(null);
  useClickOutside(calendarRef, isOpen, () => setIsOpen(false))

  const inputValue = getDisplayDate(startDate, endDate);

  const handleClick = () => setIsOpen(prevOpen => !prevOpen);

  const onRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      filterTable(start, end);
    }
  }

  const handleMonthDecrease = useCallback(() => {
    setOpenToStartDate(subtractMonths(openToStartDate, 1));
    setOpenToEndDate(subtractMonths(openToEndDate, 1));
  }, [openToStartDate, openToEndDate]);

  const handleMonthIncrease = useCallback(() => {
    setOpenToStartDate(addMonths(openToStartDate, 1));
    setOpenToEndDate(addMonths(openToEndDate, 1));
  }, [openToStartDate, openToEndDate]);

  useEffect(() => {
    if (!isOpen && startDate) {
      setOpenToStartDate(startDate);
      setOpenToEndDate(addMonths(startDate, 1));
    }
  }, [isOpen]);

  return (
    <div ref={calendarRef} className={classes.wrapper}>
      <CustomInput inputValue={inputValue} onClick={handleClick} />
      {isOpen && (
        <div className={classes.popper}>
          <DatePicker
            inline
            selectsRange
            openToDate={openToStartDate as Date}
            withPortal={false}
            closeOnScroll={false}
            showPopperArrow={false}
            focusSelectedMonth={false}
            enableTabLoop={false}
            calendarStartDay={1}
            startDate={startDate}
            endDate={endDate}
            onChange={onRangeChange}
            onYearChange={(date) => setOpenToStartDate(date)}
            renderCustomHeader={(props) => (
              <CustomHeader
                {...props}
                customHeaderCount={0}
                cb={handleMonthDecrease}
              />
            )}
            calendarClassName={!isMobileView ? classes.firstCalendar : ''}
            wrapperClassName={classes.wrapper}
          />
          {!isMobileView && (
            <DatePicker
              inline
              selectsRange
              openToDate={openToEndDate as Date}
              withPortal={false}
              closeOnScroll={false}
              showPopperArrow={false}
              focusSelectedMonth={true}
              enableTabLoop={false}
              calendarStartDay={1}
              startDate={startDate}
              endDate={endDate}
              onChange={onRangeChange}
              onYearChange={(date) => setOpenToEndDate(date)}
              renderCustomHeader={(props) => (
                <CustomHeader
                  {...props}
                  customHeaderCount={1}
                  minDate={addMonths(openToStartDate, 1)}
                  cb={handleMonthIncrease}
                />
              )}
              calendarClassName={classes.secondCalendar}
              wrapperClassName={classes.wrapper}
            />
          )}
        </div>
      )}
    </div>
  );
};
