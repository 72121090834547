import classes from "./LeaveUsAMessage.module.scss";

export const LeaveUsAMessage = () => {
  return (
    <div className={classes.leaveUsAMessageContainer}>
      <h2>Leave us a message</h2>
      <p>
        Have a question or comment? <br /> Simply fill out the form and we’ll
        be in touch!
      </p>
    </div>
  );
};
