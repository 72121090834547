import { ErrorIcon } from "../../../assets/icons/ErrorIcon";
import { InfoIcon } from "../../../assets/icons/InfoIcon";
import { SuccessIcon } from "../../../assets/icons/SuccessIcon";
import classes from "./Toast.module.scss";

export const Toast = (props: {
  className?: string;
  errorMessage?: string;
  successMessage?: string;
  type: "info" | "success" | "error";
}) => {
  const { className, errorMessage, successMessage, type } = props;

  return (
    <div className={`${classes.messageContainer} ${className}`}>
      {type === "info" && (
        <>
          <InfoIcon />
          <p className={classes.noDataMessage}>No data</p>
        </>
      )}
      {type === "error" && (
        <>
          <ErrorIcon />
          <p className={classes.noDataMessage}>{errorMessage || "Error has occurred"}</p>
        </>
      )}
      {type === "success" && (
        <>
          <SuccessIcon />
          <p className={classes.noDataMessage}>{successMessage}</p>
        </>
      )}
    </div>
  );
};
