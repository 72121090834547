import { call, put, takeLatest } from "redux-saga/effects";
import { DocumentsAPI } from "../../../api/documentsAPI";
import {
  GET_DOCUMENTS_ERROR,
  GET_DOCUMENTS_REQUEST,
  GET_DOCUMENTS_SUCCESS
} from "../../actions";

function* workerDocumentsData(): Generator<any> {
  try {
    const data: any = yield call(DocumentsAPI.getAllDocs);
    yield put({
      type: GET_DOCUMENTS_SUCCESS,
      payload: { documents: data }
    });
  } catch (error: any) {
    yield put({
      type: GET_DOCUMENTS_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherDocumentsData() {
  yield takeLatest(GET_DOCUMENTS_REQUEST, workerDocumentsData);
}
