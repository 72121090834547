import React, { useCallback, useRef, useState } from 'react';

import useClickOutside from '../../../../../../../../hooks/useClickOutside';
import { ChevronUpIcon } from "../../../../../../../../assets/icons/ChevronUpIcon";
import { ChevronDownIcon } from "../../../../../../../../assets/icons/ChevronDownIcon";
import classes from './DropDownList.module.scss';

interface IDropDownList {
  value: string;
  onChange: (e: any) => void;
  options: number[];
}

const DropDownList = ({ value, onChange, options }: IDropDownList) => {
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef(null);

  const toggleIsOpen = useCallback(() => setIsOpen((prevValue) => !prevValue), [])

  const handleOptionClick = useCallback((value: number) => () => {
    onChange(value);
    setIsOpen(false);
  }, []);

  useClickOutside(listRef, isOpen, () => setIsOpen(false))

  return (
    <div className={classes.dropDownContainer} ref={listRef}>
      <div className={classes.dropDownContainer__header} onClick={toggleIsOpen}>
        <div className={classes.dropDownContainer__header__value}>{value}</div>
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </div>
      {isOpen && (
        <div className={classes.dropDownListContainer}>
          <div className={classes.dropDownList}>
            {options.map(option => (
              <div key={option} className={classes.dropDownList__item} onClick={handleOptionClick(option)}>
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>

  )
}

export default DropDownList;
