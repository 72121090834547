import { L12M, L30D, N30D } from "../../utils/constants/filterConstants";
import { updateOneEntity } from "../../utils/reduxUtils";
import { IReduxAction } from "../../views/interfaces";
import { GET_REVIEWS_SUCCESS } from "../actions";

const initialState = {
  [L30D]: null,
  [L12M]: null,
  [N30D]: null
};

export const ratingReducer = (
  state = initialState,
  { type, payload }: IReduxAction
) => {
  switch (type) {
    case GET_REVIEWS_SUCCESS: {
      return updateOneEntity(state, payload);
    }
    default:
      return state;
  }
};
