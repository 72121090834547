import { Divider } from "@mui/material";
import classes from "./CalendarReservationsHighlights.module.scss";
import { Highlight } from "../../../../../common/BasicHighlight/BasicHighlight";
import { isNumber } from "../../../../../../utils/commonUtils";
import { useSelector } from "react-redux";
import { isMobileModeSelector } from "../../../../../../redux/selectors/appStatusSelector";

export const CalendarReservationsHighlights = ({ data }: any) => {
  const isMobileView = useSelector(isMobileModeSelector);

  if (!data) return null;

  const {
    guestsHosted,
    bookedNights,
    ownerBlocks,
    paidReservations,
    GuestsHosted,
    NightsBooked,
    OwnersBlocks,
    Reservations
  } = data;

  return (
    <>
      {data && !isMobileView ? (
        <div className={classes.reservationsHighlightsWrapper}>
          <>
            {isNumber(Number(NightsBooked)) && (
              <>
                <Highlight
                  label="Nights Booked"
                  tooltip={
                    "This is the average number of nights that your property was occupied for a given reservation"
                  }
                  showDynamicIndicator={false}
                  value={NightsBooked}
                />
                <Divider flexItem orientation="vertical" />
              </>
            )}
            {isNumber(Number(Reservations)) && (
              <>
                <Highlight
                  label="Paid Reservations"
                  tooltip={
                    "This is the percentage of Nights Sold to guests out of the Total Number of Nights. This number is calculated by taking the number of Nights Sold to guests and dividing it by the Total Number of Nights"
                  }
                  showDynamicIndicator={false}
                  value={Reservations}
                />
                <Divider flexItem orientation="vertical" />
              </>
            )}
            {isNumber(Number(GuestsHosted)) && (
              <>
                <Highlight
                  label="Guests Hosted"
                  tooltip={
                    "This number indicates the total number of guests you have hosted at this property in the last period"
                  }
                  showDynamicIndicator={false}
                  value={GuestsHosted}
                />
                <Divider flexItem orientation="vertical" />
              </>
            )}
            {isNumber(Number(OwnersBlocks || ownerBlocks)) && (
              <Highlight
                label="Owner Blocks"
                tooltip={
                  "This is the percentage of Nights Booked by an Owner (unavailable for guest reservations). This number is calculated by taking the number of Nights Booked by an Owner and dividing it by the Total Number of Nights"
                }
                showDynamicIndicator={false}
                value={OwnersBlocks || ownerBlocks}
              />
            )}
          </>
        </div>
      ) : (
        <div className={classes.reservationsHighlightsWrapper}>
          <div className={classes.reservationsHighlightsRow}>
            {isNumber(bookedNights) && (
              <>
                <Highlight
                  className={classes.calendarHighlight}
                  label="Nights Booked"
                  tooltip={
                    "This is the average number of nights that your property was occupied for a given reservation"
                  }
                  showDynamicIndicator={false}
                  value={bookedNights}
                  reverse={true}
                />
              </>
            )}
            {isNumber(guestsHosted) && (
              <>
                <Highlight
                  className={classes.calendarHighlight}
                  label="Guests Hosted"
                  tooltip={
                    "This number indicates the total number of guests you have hosted at this property in the last period"
                  }
                  showDynamicIndicator={false}
                  value={guestsHosted}
                  reverse={true}
                />
              </>
            )}
          </div>
          <Divider flexItem orientation="vertical" />
          <div className={classes.reservationsHighlightsRow}>
            {isNumber(ownerBlocks) && (
              <Highlight
                className={classes.calendarHighlight}
                label="Owner Blocks"
                tooltip={
                  "This is the percentage of Nights Booked by an Owner (unavailable for guest reservations). This number is calculated by taking the number of Nights Booked by an Owner and dividing it by the Total Number of Nights"
                }
                showDynamicIndicator={false}
                value={ownerBlocks}
                reverse={true}
              />
            )}
            {isNumber(paidReservations) && (
              <>
                <Highlight
                  className={classes.calendarHighlight}
                  label="Paid Reservations"
                  tooltip={
                    "This is the percentage of Nights Sold to guests out of the Total Number of Nights. This number is calculated by taking the number of Nights Sold to guests and dividing it by the Total Number of Nights"
                  }
                  showDynamicIndicator={false}
                  value={paidReservations}
                  reverse={true}
                />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
