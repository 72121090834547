import {
  createErrorSelector,
  createLoadingSelector
} from "../../utils/reduxUtils";
import {
  GET_REVENUE,
  GET_PER_NIGHT_REVENUE,
  GET_REVENUE_BY_MONTH,
  GET_YEARLY_REVENUE,
  GET_RESERVATIONS_BY_CHANNEL
} from "../actions";

export const totalRevenueSelector = (state: any): number =>
  state.revenue.totalRevenue;

export const perNightRevenueSelector = (period: string) => (state: any) =>
  state.revenue.perNightRevenue[period];

export const revenueByMonthSelector = (year: string) => (state: any) =>
  state.revenue.revenueByMonth?.[year];

export const yearlyRevenueSelector = (state: any) =>
  state.revenue.revenueByYear;

export const reservationsPerChannelSelector = (period: string) => (
  state: any
) => state.revenue.reservations[period];

export const totalRevenueLoadingSelector = createLoadingSelector([GET_REVENUE]);
export const totalRevenueErrorSelector = createErrorSelector([GET_REVENUE]);

export const perNightRevenueLoadingSelector = createLoadingSelector([
  GET_PER_NIGHT_REVENUE
]);
export const perNightRevenueErrorSelector = createErrorSelector([
  GET_PER_NIGHT_REVENUE
]);

export const revenueByMonthLoadingSelector = createLoadingSelector([
  GET_REVENUE_BY_MONTH
]);
export const revenueByMonthErrorSelector = createErrorSelector([
  GET_REVENUE_BY_MONTH
]);

export const yearlyRevenueLoadingSelector = createLoadingSelector([
  GET_YEARLY_REVENUE
]);
export const yearlyRevenueErrorSelector = createErrorSelector([
  GET_YEARLY_REVENUE
]);

export const reservationsPerChannelLoadingSelector = createLoadingSelector([
  GET_RESERVATIONS_BY_CHANNEL
]);
export const reservationsPerChannelErrorSelector = createErrorSelector([
  GET_RESERVATIONS_BY_CHANNEL
]);
