export const OccupancyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9937 11.9742C23.9937 12.8178 23.3687 13.4786 22.6603 13.4786H21.3268L21.356 20.9865C21.3643 22.6503 20.1684 24 18.6891 24H5.33795C3.867 24 2.67106 22.655 2.67106 21.0006V13.4786H1.33345C0.583383 13.4786 0 12.8225 0 11.9742C0 11.5524 0.125011 11.1775 0.416702 10.8494L11.1009 0.374927C11.3926 0.0468658 11.726 0 12.0177 0C12.3094 0 12.6427 0.0937317 12.8928 0.328061L23.5353 10.8494C23.8687 11.1775 24.0354 11.5524 23.9937 11.9742ZM14.6679 10.4979C14.6679 8.84359 13.472 7.49854 12.001 7.49854C10.5301 7.49854 9.33413 8.84359 9.33413 10.4979C9.33413 12.1523 10.5301 13.4974 12.001 13.4974C13.472 13.4974 14.6679 12.1523 14.6679 10.4979ZM10.6676 14.9971C8.82575 14.9971 7.33396 16.6749 7.33396 18.7463C7.33396 19.1588 7.63398 19.4962 8.00068 19.4962H16.0014C16.3681 19.4962 16.6681 19.1588 16.6681 18.7463C16.6681 16.6749 15.1763 14.9971 13.3345 14.9971H10.6676Z"
      fill="#B4B7CF"
    />
  </svg>
);
