import { updateState } from "../../utils/reduxUtils"
import { IReduxAction } from "../../views/interfaces"
import { GET_DOCUMENTS_SUCCESS } from "../actions"

const initialState = {
  documents: null
};

export const documentsReducer = (state = initialState, { type, payload }: IReduxAction) => {
  switch (type) {
    case GET_DOCUMENTS_SUCCESS: {
      return updateState(state, payload);
    }
    default: {
      return state;
    }
  }
}