import { L12M, L30D, N30D } from "../../utils/constants/filterConstants";
import { updateOneEntity, updateState } from "../../utils/reduxUtils";
import { IReduxAction } from "../../views/interfaces";
import {
  GET_PER_NIGHT_REVENUE_SUCCESS,
  GET_RESERVATIONS_BY_CHANNEL_SUCCESS,
  GET_REVENUE_BY_MONTH_SUCCESS,
  GET_REVENUE_SUCCESS,
  GET_YEARLY_REVENUE_SUCCESS
} from "../actions";

const initialState = {
  totalRevenue: null,
  revenueByYear: null,
  revenueByMonth: null,
  perNightRevenue: { 
    [L30D]: null,
    [L12M]: null,
    [N30D]: null,
  },
  reservations: {
    [L30D]: null,
    [L12M]: null,
    [N30D]: null
  }
};

export const revenueReducer = (state = initialState, { type, payload }: IReduxAction) => {
  switch (type) {
    case GET_REVENUE_SUCCESS:
    case GET_YEARLY_REVENUE_SUCCESS: {
      return updateState(state, payload);
    }
    case GET_PER_NIGHT_REVENUE_SUCCESS:
    case GET_RESERVATIONS_BY_CHANNEL_SUCCESS:
    case GET_REVENUE_BY_MONTH_SUCCESS: {
      return updateOneEntity(state, payload);
    }
    default:
      return state;
  }
};
