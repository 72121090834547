import { IDailyIccupancy, IListing, IReservation } from "../views/interfaces";
import { getChannel, toWholePercent } from "./commonUtils";

export const processDailyOccupancy = (dailyOccupancy: IDailyIccupancy[]) => {
  return dailyOccupancy
    ? dailyOccupancy.map(
        ({ Day_of_week, occupancy_rate }: IDailyIccupancy) => ({
          Day_of_week: Day_of_week.slice(0, 3),
          occupancy_rate: toWholePercent(occupancy_rate)
        })
      )
    : null;
};

export const processListings = (listings: IListing[]) => {
  const processedListings = listings.map((listing: IListing) => {
    const { channels, is_listed, link, listing_id } = listing;
    if (link) {
      return Object.values(link).map((linkToListing: string, index: number) => {
        const channelKey: string = getChannel(channels?.[index]);
        return { active: is_listed, channel: channelKey, link: linkToListing, listing_id };
      });
    } else if (channels) {
      return Object.values(channels).map((channel: any) => {
        const channelKey = getChannel(channel);
        return { active: is_listed, channel: channelKey, link, listing_id };
      });
    }
    return { active: is_listed, channel: "", link, listing_id };
  });

  return processedListings.flat();
};

export function processReservationsData(reservations: IReservation[]) {
  const reservationsMap = {
    previous: {},
    upcoming: {},
    previousHighlightsData: {
      ownerBlocks: 0,
      paidReservations: 0,
      bookedNights: 0,
      guestsHosted: 0,
    },
    upcomingHighlightsData: {
      ownerBlocks: 0,
      paidReservations: 0,
      bookedNights: 0,
      guestsHosted: 0,
    },
  };

  return reservations.reduce((obj: any, reservation) => {
    const { checkin_at, status } = reservation;
    if (status === "canceled") {
      return obj;
    }

    const checkinDate = new Date(checkin_at);
    const today = new Date();

    const year: number = checkinDate.getFullYear();
    const month: number = checkinDate.getMonth();
    const day: number = checkinDate.getDate();

    const reservationWithOnlyDates = {
      ...reservation,
      checkin_at: reservation.checkin_at.split(" ")[0],
      checkout_at: reservation.checkout_at.split(" ")[0]
    };

    if (checkinDate > today) {
      setProperty(obj.upcoming, year, month, day, reservationWithOnlyDates);
      setHighlightsData(obj.upcomingHighlightsData, reservation);
    } else {
      setProperty(obj.previous, year, month, day, reservationWithOnlyDates);
      setHighlightsData(obj.previousHighlightsData, reservation);
    }

    return obj;
  }, reservationsMap);
}

export function filterReservationsByDateRange(
  reservations: IReservation[],
  startDate: Date,
  endDate: Date
) {
  const today = new Date();

  const selectedReservations = {
    previous: {},
    upcoming: {},
    previousHighlightsData: {
      ownerBlocks: 0,
      paidReservations: 0,
      bookedNights: 0,
      guestsHosted: 0,
    },
    upcomingHighlightsData: {
      ownerBlocks: 0,
      paidReservations: 0,
      bookedNights: 0,
      guestsHosted: 0,
    },
  };

  return reservations.reduce((obj: any, reservation) => {
    const { checkin_at, status } = reservation;
    const checkinDate = new Date(checkin_at);
    const year: number = checkinDate.getFullYear();
    const month: number = checkinDate.getMonth();
    const day: number = checkinDate.getDate();

    if (
      checkinDate >= startDate &&
      checkinDate <= endDate &&
      status !== "canceled"
    ) {
      if (checkinDate > today) {
        setProperty(obj.upcoming, year, month, day, reservation);
        setHighlightsData(obj.upcomingHighlightsData, reservation);
      } else {
        setProperty(obj.previous, year, month, day, reservation);
        setHighlightsData(obj.previousHighlightsData, reservation);
      }
    }

    return obj;
  }, selectedReservations);
}

const setProperty = (
  obj: any,
  year: number,
  month: number,
  day: number,
  reservation: object
) => {
  if (obj?.[year]) {
    if (obj[year]?.[month]) {
      obj[year][month] = {
        ...obj[year][month],
        [day]: reservation
      };
    } else {
      obj[year] = {
        ...obj[year],
        [month]: {
          [day]: reservation
        }
      };
    }
  } else {
    obj[year] = {
      [month]: {
        [day]: reservation
      }
    };
  }

  return obj;
};

const setHighlightsData = (obj: any, reservation: IReservation) => {
  const { nights_count, guest_count, netincome } = reservation;

  obj.bookedNights += nights_count;
  obj.guestsHosted += guest_count
    ? Number(guest_count)
    : 0;

  if (netincome) {
    obj.paidReservations += 1;
  } else {
    obj.ownerBlocks += 1;
  }
}
