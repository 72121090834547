import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import classes from "./FullPageContainer.module.scss";
import { USER_DATA_REQUEST, USER_LOGO_REQUEST } from '../../../redux/actions';
import { isMobileModeSelector } from "../../../redux/selectors/appStatusSelector";
import {
  userDataSelector,
  userListingsSelector, userLogoSelector
} from '../../../redux/selectors/userDataSelector';
import { useCurrentPath } from "../../../utils/router";
import { IFullPageView, IListing, IView } from "../../interfaces";
import { DesktopHeader } from "../DesktopHeader/DesktopHeader";
import { DesktopMenu } from "../DesktopMenu/DesktopMenu";
import { mainMenuItems } from "../MenuItems/MenuItems";
import { MobileFooterMenu } from "../MobileFooterMenu/MobileFooterMenu";
import { MobileHeader } from "../MobileHeader/MobileHeader";
import { getAuthToken } from "../../../utils/apiUtils";

const DesktopView = ({
  activePath,
  children,
  className,
  listing,
  logo,
  navigate,
  userName
}: IView) => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className={classes.desktopPageContainer}>
      <DesktopHeader
        handleMenu={setOpenMenu}
        listing={listing}
        logo={logo}
        open={openMenu}
        userName={userName}
      />
      <DesktopMenu
        activePath={activePath}
        handleMenu={setOpenMenu}
        navigate={navigate}
        open={openMenu}
      />
      <div className={`${classes.contentWrapper} ${className}`}>{children}</div>
    </div>
  );
};

const MobileView = ({
  activePath,
  children,
  className,
  listing,
  logo,
  navigate,
  userName
}: IView) => {
  return (
    <div className={classes.mobilePageContainer}>
      <MobileHeader
        activePath={activePath}
        listing={listing}
        logo={logo}
        navigate={navigate}
        userName={userName}
      />
      <MobileFooterMenu
        activePath={activePath}
        list={mainMenuItems.filter( item => item.id != 'rating' || item.id == 'rating' && userName == 'pricelabs'  ) }
        navigate={navigate}
      />
      <div className={`${classes.mobileContentWrapper} ${className}`}>
        {children}
      </div>
      {/* TODO: Update mobile view styles */}
    </div>
  );
};

export const FullPageContainer = ({ children, className }: IFullPageView) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobileView = useSelector(isMobileModeSelector);
  const token = getAuthToken();
  const userData = useSelector(userDataSelector);
  const logo = useSelector(userLogoSelector)
  const listings = useSelector(userListingsSelector);

  const activePath = useCurrentPath();

  const listing = listings?.find(({ is_listed }: IListing) => is_listed);

  useEffect(() => {
    if (token && (!listings || !userData)) {
      dispatch({
        type: USER_DATA_REQUEST,
      });
    }
  });

  useEffect(() => {
    if (token && !logo) {
      dispatch({
        type: USER_LOGO_REQUEST,
      });
    }
  })

  const props = {
    activePath,
    children,
    className,
    listing: listing?.nickname,
    logo,
    navigate,
    userName: userData?.name
  };

  return (
    <>
      {!token && <Navigate to="/login" replace />}
      {listings && userData && (
        <>
          {isMobileView ? (
            <MobileView {...props} />
          ) : (
            <DesktopView {...props} />
          )}
        </>
      )}
    </>
  );
};
