import { RouterProvider } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme";
import { router } from "./utils/router";
import { MobileDetector } from "./views/common/MobileDetector/MobileDetector";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
        <MobileDetector />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
