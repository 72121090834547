export const RatingIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="2" fill="#B4B7CF" />
    <path
      d="M11.9998 16.7286L8.60676 18.8689C8.45687 18.9688 8.30016 19.0116 8.13664 18.9973C7.97312 18.9831 7.83003 18.926 7.70739 18.8261C7.58475 18.7262 7.48936 18.6015 7.42123 18.452C7.3531 18.3019 7.33947 18.1341 7.38035 17.9486L8.27972 13.9035L5.275 11.1854C5.13873 11.0569 5.0537 10.9106 5.01991 10.7462C4.98557 10.5824 4.99565 10.422 5.05016 10.265C5.10467 10.1081 5.18643 9.97968 5.29544 9.8798C5.40446 9.77992 5.55435 9.71571 5.74513 9.68718L9.71054 9.32333L11.2436 5.51366C11.3117 5.34244 11.4174 5.21403 11.5608 5.12842C11.7036 5.04281 11.85 5 11.9998 5C12.1497 5 12.2964 5.04281 12.4397 5.12842C12.5825 5.21403 12.688 5.34244 12.7561 5.51366L14.2892 9.32333L18.2546 9.68718C18.4453 9.71571 18.5952 9.77992 18.7042 9.8798C18.8133 9.97968 18.895 10.1081 18.9495 10.265C19.004 10.422 19.0144 10.5824 18.9806 10.7462C18.9463 10.9106 18.861 11.0569 18.7247 11.1854L15.72 13.9035L16.6193 17.9486C16.6602 18.1341 16.6466 18.3019 16.5785 18.452C16.5103 18.6015 16.4149 18.7262 16.2923 18.8261C16.1697 18.926 16.0266 18.9831 15.8631 18.9973C15.6995 19.0116 15.5428 18.9688 15.3929 18.8689L11.9998 16.7286Z"
      fill="white"
    />
  </svg>
);
