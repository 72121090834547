import { OutlinedInput } from '@mui/material';
import { forwardRef } from 'react';

import { CalendarIcon } from '../../../../../../../../assets/icons/CalendarIcon';
import classes from './CustomInput.module.scss';

interface IDatePickerInput {
  inputValue: string;
  onClick: any;
}

const CustomInput = forwardRef(
  ({ inputValue,  onClick }: IDatePickerInput, ref) => (
    <OutlinedInput
      aria-describedby="select-dates"
      classes={{
        root: classes.input,
        input: classes.outlined,
        notchedOutline: classes.notchedOutline,
      }}
      endAdornment={
        <div className={classes.adornedEnd}>
          <CalendarIcon color="#424581" />
        </div>
      }
      id="select-dates"
      inputProps={{
        "aria-label": "weight"
      }}
      placeholder={"Select Dates"}
      readOnly
      onClick={onClick}
      ref={ref}
      value={inputValue}
    />
  )
);

export default CustomInput;
