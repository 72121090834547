export const ForgotPassword = (props: { className: string; }) => (
  <svg
    className={props.className}
    width="66"
    height="75"
    viewBox="0 0 66 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.4284 25H61.5712C62.5184 25 63.4268 25.3763 64.0966 26.046C64.7664 26.7158 65.1426 27.6242 65.1426 28.5714V71.4286C65.1426 72.3758 64.7664 73.2842 64.0966 73.954C63.4268 74.6237 62.5184 75 61.5712 75H4.42836C3.48116 75 2.57275 74.6237 1.90298 73.954C1.23321 73.2842 0.856934 72.3758 0.856934 71.4286V28.5714C0.856934 27.6242 1.23321 26.7158 1.90298 26.046C2.57275 25.3763 3.48116 25 4.42836 25H11.5712V21.4286C11.5712 15.7454 13.8289 10.2949 17.8475 6.27628C21.8661 2.25765 27.3166 0 32.9998 0C38.683 0 44.1334 2.25765 48.1521 6.27628C52.1707 10.2949 54.4284 15.7454 54.4284 21.4286V25ZM47.2855 25V21.4286C47.2855 17.6398 45.7804 14.0061 43.1013 11.327C40.4222 8.64795 36.7886 7.14286 32.9998 7.14286C29.211 7.14286 25.5774 8.64795 22.8983 11.327C20.2192 14.0061 18.7141 17.6398 18.7141 21.4286V25H47.2855ZM29.4284 46.4286V53.5714H36.5712V46.4286H29.4284ZM15.1426 46.4286V53.5714H22.2855V46.4286H15.1426ZM43.7141 46.4286V53.5714H50.8569V46.4286H43.7141Z"
      fill="#F5F5FC"
    />
  </svg>
);
