import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./CalendarPage.module.scss";
import { GET_RESERVATIONS_DATA_REQUEST } from "../../../redux/actions";
import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import { BigCalendar } from "./components/BigCalendar/BigCalendar";
import SmallCalendar from "./components/SmallCalendar/SmallCalendar";
import { UpcomingReservations } from "./components/UpcomingReservations/UpcomingReservations";
import {
  previousReservationsSelector,
  upcomingReservationsSelector,
} from "../../../redux/selectors/reservationsData";
import { isMobileModeSelector } from "../../../redux/selectors/appStatusSelector";

export const CalendarPage = () => {
  const dispatch = useDispatch();
  const previousReserv: any = useSelector(previousReservationsSelector);
  const upcomingReserv: any = useSelector(upcomingReservationsSelector);
  const reservationsData = { ...previousReserv, ...upcomingReserv };

  const isMobileView = useSelector(isMobileModeSelector);

  useEffect(() => {
    dispatch({
      type: GET_RESERVATIONS_DATA_REQUEST,
    });
  }, [dispatch]);

  return (
    <FullPageContainer className={classes.calendarContainer}>
      <>
        {!isMobileView && (
          <div className={classes.column}>
            <SmallCalendar reservationsData={reservationsData} />
            <UpcomingReservations />
          </div>
        )}
        <BigCalendar reservationsData={reservationsData} />
        {isMobileView && <UpcomingReservations />}
      </>
    </FullPageContainer>
  );
};
