export const ListingsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3333 0H2.66667C1.2 0 0 1.2 0 2.66667V21.3333C0 22.8 1.2 24 2.66667 24H21.3333C22.8 24 24 22.8 24 21.3333V2.66667C24 1.2 22.8 0 21.3333 0ZM5.33333 5.33333H8V8H5.33333V5.33333ZM5.33333 10.6667H8V13.3333H5.33333V10.6667ZM5.33333 16H8V18.6667H5.33333V16ZM18.6667 18.6667H10.6667V16H18.6667V18.6667ZM18.6667 13.3333H10.6667V10.6667H18.6667V13.3333ZM18.6667 8H10.6667V5.33333H18.6667V8Z"
      fill="#B4B7CF"
    />
  </svg>
);
