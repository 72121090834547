import { Skeleton } from "@mui/material";
import classes from "./Loader.module.scss"
import { ILoader } from "../../interfaces";
import { Toast } from "../Toast/Toast";

export const Loader = ({ isLoading, isError, data }: ILoader) => {
  if (!isLoading && isError && data) return null

  return (
    <>
      {isLoading && !isError && (
        <Skeleton animation="wave" className={classes.rectangular} height={100} variant="rectangular" width={100}/>
      )}
      {!isLoading && !isError && !data && <Toast type="info" />}
      {!isLoading && isError && <Toast type="error" />}
    </>
  );
}
