import { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput
} from "@mui/material";
import classes from "./ContactForm.module.scss";
import { SEND_CONTACTS_REQUEST } from "../../../../../redux/actions";
import {isMobileModeSelector} from "../../../../../redux/selectors/appStatusSelector";

export const ContactForm = (props: {
  isError: boolean;
  isLoading: boolean;
  submitted: boolean;
  setSubmitted: Function;
}) => {
  const { isError, isLoading, submitted, setSubmitted } = props;

  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const isMobileView = useSelector(isMobileModeSelector);

  const handleSubmit = () => {
    dispatch({
      type: SEND_CONTACTS_REQUEST,
      payload: {
        name,
        surname,
        email,
        message
      }
    });
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted && !isLoading && !isError) {
      setName("");
      setSurname("");
      setEmail("");
      setMessage("");
      setTimeout(() => {
        setSubmitted(false);
      }, 4000)
    }
  }, [isError, isLoading, submitted, setSubmitted]);

  return (
    <div className={classes.contactForm}>
      <div className={classes.nameInputs}>
        <FormControl>
          {isMobileView ? <p className={classes.label}>First Name</p> : null}
          <OutlinedInput
            className={classes.input}
            id="name"
            name="name"
            onChange={event => setName(event.target.value)}
            placeholder="Name"
            type="text"
            value={name}
          />
        </FormControl>
        <FormControl>
          {isMobileView ? <p className={classes.label}>Last Name</p> : null}
          <OutlinedInput
            className={classes.input}
            id="surname"
            name="surname"
            onChange={event => setSurname(event.target.value)}
            placeholder="Last Name"
            type="text"
            value={surname}
          />
        </FormControl>
      </div>
      <FormControl fullWidth>
        {isMobileView ? <p className={classes.label}>Email</p> : null}
        <OutlinedInput
          id="email"
          fullWidth
          name="email"
          onChange={event => setEmail(event.target.value)}
          placeholder="Enter your email"
          type="email"
          value={email}
        />
      </FormControl>
      <FormControl fullWidth>
        {isMobileView ? <p className={classes.label}>Message</p> : null}
        <OutlinedInput
          id="message"
          fullWidth
          name="message"
          multiline
          onChange={event => setMessage(event.target.value)}
          placeholder="Write your message here"
          rows={3}
          type="text"
          value={message}
        />
      </FormControl>
      <div className={classes.action}>
        <Button
          sx={{
            borderRadius: isMobileView ? '24px' : '16px',
            height: isMobileView ? '62px' : '56px',
            minWidth: isMobileView ? '100%' : '208px',
            marginTop: isMobileView ? '5px' : 0,
          }}
          color="primary"
          onClick={handleSubmit}
          size="large"
          variant="contained"
        >
          {submitted && isLoading ? <CircularProgress /> : "Submit"}
        </Button>
      </div>
    </div>
  );
};
