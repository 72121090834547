import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { IOccupancyRateChart } from "../../../../interfaces";
import { useSelector } from "react-redux";
import { isMobileModeSelector } from "../../../../../redux/selectors/appStatusSelector";
import classes from "./OccupancyRateLineChart.module.scss";
import { useEffect, useRef, useState } from "react";

const MyBackground = (props: any) => {
  const { x, y, width, height, points, isMobileView } = props;
  return (
    <>
      <defs>
        <linearGradient id="g1" x1=".5" x2=".5" y2="1">
          <stop stopColor="#fcfcfd" stopOpacity="0" />
          <stop offset="1" stopColor="#5d6293" stopOpacity=".15" />
        </linearGradient>
      </defs>
      <rect
        x={points[0].x - 20}
        y={y}
        width={40}
        height={isMobileView ? height : height + 5}
        fill="url(#g1)"
        stroke="none"
        rx={12}
      />
    </>
  );
};

const CustomTooltip = (props: any) => {
  const {
    active,
    payload,
    viewBox,
    isMobileView,
    rightTooltipHeight,
    cartesianGridBottomOffset,
    leftTooltipWidth,
    arrowWidth,
    leftTooltipRef,
    rightTooltipRef
  } = props;
  const leftMonths = isMobileView ? ['Jan', 'Feb', 'Mar'] : ['Jan'];
  const rightMonths = isMobileView ? ['Oct', 'Nov', 'Dec'] : ['Nov', 'Dec'];

  if (active && payload && payload.length) {
    const leftDataPoint = payload[0].payload;
    const rightDataPoint = payload[1].payload;
    const leftName = payload[0].name;
    const rightName = payload[1].name;
    const isLeftMonth = leftMonths.includes(leftDataPoint.name);
    const isRightMonth = rightMonths.includes(rightDataPoint.name);

    const dataPointDifference =
      (Math.abs(leftDataPoint[leftName] - rightDataPoint[rightName]) *
        viewBox?.height) /
      100;

    const isTooltipsOnDifferentSides = dataPointDifference < rightTooltipHeight;

    return (
      <>
        <div
          className={`${classes.tooltip} ${
            !isLeftMonth ? classes.leftTooltip : classes.rightTooltip
          }`}
          style={{
            // bottom:
            //   (viewBox?.height * leftDataPoint[leftName]) / 100 -
            //   cartesianGridBottomOffset +
            //   4,
            bottom:
              (viewBox?.height * leftDataPoint[leftName]) / 100 -
              cartesianGridBottomOffset +
              4,
            left: !isLeftMonth
            ? (-leftTooltipWidth - arrowWidth - 2)
            : (arrowWidth + 2),
          }}
          ref={leftTooltipRef}
        >
          <p>{`${leftDataPoint[leftName]}%`}</p>
        </div>

        <div
          className={`${classes.tooltip} ${!isRightMonth ? classes.rightTooltip : classes.leftTooltip}`}
          style={{
            bottom:
              (viewBox?.height * rightDataPoint[rightName]) / 100 -
              cartesianGridBottomOffset +
              4,
            left: !isRightMonth ? arrowWidth + 2 : arrowWidth + 2 - leftTooltipWidth,
          }}
          ref={rightTooltipRef}
        >
          <p>{`${rightDataPoint[rightName]}%`}</p>
        </div>
      </>
    );
  }

  return null;
};

const CustomYAxisTick = (props: any) => {
  const { x, y, payload, isMobileView } = props;
  return (
    <g transform={`translate(${isMobileView ? 0 : 20},${y})`}>
      <text x={0} y={0} textAnchor="start" fontSize={12} fill="#9798A5">
        {payload.value === 0 ? payload.value : `${payload.value}%`}
      </text>
    </g>
  );
};

export const OccupancyRateLineChart = ({
  data,
  fromYear,
  toYear,
}: IOccupancyRateChart) => {
  const isMobileView = useSelector(isMobileModeSelector);
  const arrowWidth = 6;

  const responsiveContainerRef = useRef<any>(null);
  const cartesianGridRef = useRef<any>(null);
  const leftTooltipRef = useRef<any>(null);
  const rightTooltipRef = useRef<any>(null);

  const [activeTooltipIndex, setActiveTooltipIndex] = useState<
    number | undefined
  >();
  const [cartesianGridWidth, setCartesianGridWidth] = useState(0);
  const [cartesianGridHeight, setCartesianGridHeight] = useState(0);
  const [cartesianGridLeftOffset, setCartesianGridLeftOffset] = useState(0);
  const [cartesianGridBottomOffset, setCartesianGridBottomOffset] = useState(0);
  const [leftTooltipWidth, setLeftTooltipWidth] = useState(0);
  const [rightTooltipHeight, setRightTooltipHeight] = useState(0);

  useEffect(() => {
    const responsiveContainer = responsiveContainerRef?.current?.current;
    const cartesianGrid =
      cartesianGridRef?.current?._reactInternals?.child?.stateNode;
    const leftTooltip = leftTooltipRef?.current;
    const rightTooltip = rightTooltipRef?.current;

    cartesianGrid &&
      responsiveContainer &&
      setCartesianGridLeftOffset(
        cartesianGrid.getBoundingClientRect().left -
          responsiveContainer.getBoundingClientRect().left
      );
    cartesianGrid &&
      responsiveContainer &&
      setCartesianGridBottomOffset(
        responsiveContainer.getBoundingClientRect().bottom -
          cartesianGrid.getBoundingClientRect().bottom
      );

    cartesianGrid &&
      setCartesianGridWidth(cartesianGrid.getBoundingClientRect().width);
    cartesianGrid &&
      setCartesianGridHeight(cartesianGrid.getBoundingClientRect().height);
    leftTooltip &&
      setLeftTooltipWidth(leftTooltip.getBoundingClientRect().width);
    rightTooltip &&
      setRightTooltipHeight(rightTooltip.getBoundingClientRect().height);
  }, [activeTooltipIndex]);

  return (
    <>
      {data && (
        <ResponsiveContainer
          height={isMobileView ? 280 : "80%"}
          width="100%"
          ref={responsiveContainerRef}
        >
          <LineChart
            data={data}
            onMouseMove={({ activeTooltipIndex }) =>
              setActiveTooltipIndex(activeTooltipIndex)
            }
            onMouseLeave={() => setActiveTooltipIndex(undefined)}
            margin={isMobileView
              ? { top: 0, left: 0, right: 16, bottom: 0 }
              : { top: 5, left: 5, right: 5, bottom: 5 }
            }
          >
            <CartesianGrid
              stroke="#B4B7CF"
              strokeDasharray="2.33 4.66"
              strokeWidth="0.5"
              vertical={false}
              ref={cartesianGridRef}
            />
            <XAxis
              axisLine={false}
              dataKey="name"
              tickLine={false}
              interval={isMobileView ? 0 : undefined}
              tick={{ fill: "#9798A5", fontSize: isMobileView ? 10 : 14 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              unit="%"
              tick={<CustomYAxisTick isMobileView={isMobileView} />}
              width={isMobileView ? 44 : 60}
            />
            <Tooltip
              position={{
                x: activeTooltipIndex
                  ? cartesianGridLeftOffset +
                    (activeTooltipIndex * cartesianGridWidth) /
                      (data.length - 1)
                  : cartesianGridLeftOffset,
                y: cartesianGridHeight - 11 - (isMobileView ? 4 : 0),
              }}
              content={<CustomTooltip
                isMobileView={isMobileView}
                rightTooltipHeight={rightTooltipHeight}
                cartesianGridBottomOffset={cartesianGridBottomOffset}
                leftTooltipWidth={leftTooltipWidth}
                arrowWidth={arrowWidth}
                leftTooltipRef={leftTooltipRef}
                rightTooltipRef={rightTooltipRef}
              />}
              cursor={<MyBackground isMobileView={isMobileView} />}
            />
            <Line
              dataKey={fromYear}
              isAnimationActive={false}
              stroke="#00B48D"
              strokeWidth={3}
              type="monotone"
              filter="drop-shadow(0px 7px 5px rgba(93, 98, 147, 0.43))"
              dot={false}
            />
            <Line
              dataKey={toYear}
              isAnimationActive={false}
              stroke="#5D6293"
              strokeWidth={3}
              type="monotone"
              filter="drop-shadow(0px 7px 5px rgba(93, 98, 147, 0.43))"
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
