import { call, put, takeLatest } from "redux-saga/effects";
import { UserLoginAPI } from "../../../api/loginUserAPI";
import {
  LOGIN_USER_ERROR,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS
} from "../../actions";

function* workerLoginUser(action: {
  type: string;
  payload: { property_id: string; password: string; navigate: Function };
}): Generator<any> {
  try {
    const { property_id, password, navigate } = action.payload;
    const data: any = yield call(UserLoginAPI.loginUser, {
      property_id,
      password
    });
    yield sessionStorage.setItem("AH_JWT_TOKEN", data.token);
    yield put({
        type: LOGIN_USER_SUCCESS,
        payload: { property_id: action.payload.property_id }
      });
    yield navigate("/perfomance");
  } catch (error: any) {
    yield put({ type: LOGIN_USER_ERROR, payload: { message: error.message } });
  }
}

export function* watcherLoginUser() {
  yield takeLatest(LOGIN_USER_REQUEST, workerLoginUser);
}
