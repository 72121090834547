import { requestHandler, getServiceURL } from "../utils/apiUtils";

export class ContactsAPI {
  static get sendContactsServiceName() {
    return "CONTACT";
  }

  static async sendContacts(data: {
    name: string;
    surname: string;
    email: string;
    message: string;
  }): Promise<any> {
    const url = getServiceURL(ContactsAPI.sendContactsServiceName);
    const response = await requestHandler(url, data);
    return response?.data;
  }
}
