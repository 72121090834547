import { useState } from "react";
import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import { DarkInfoIcon } from "../../../assets/icons/DarkInfoIcon";
import { IBasicTooltip } from "../../interfaces";
import classes from "./BasicTooltip.module.scss";

export const BasicTooltip = ({ tooltip }: IBasicTooltip) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        placement="top"
        title={tooltip}
        open={open}
      >
        <IconButton onClick={handleTooltipOpen} onMouseOver={handleTooltipOpen} onMouseOut={handleTooltipClose}>
          <DarkInfoIcon />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}
