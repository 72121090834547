import { all, call, put, takeLatest } from "redux-saga/effects";
import { ReservationsAPI } from "../../../api/reservationsAPI"
import {
  RESERVATIONS_PREVIOUS_INDICATORS_SUCCESS,
  RESERVATIONS_PREVIOUS_INDICATORS_REQUEST,
  RESERVATIONS_PREVIOUS_INDICATORS_ERROR
} from "../../actions";

function* workerPreviousReservationsIndicators(action: {
  type: string;
  payload: { param: string };
}): Generator<any> {
  try {
    const params = action.payload;
    const data: any = yield all([
      call(ReservationsAPI.getSumPreviousNightsBooked),
      call(ReservationsAPI.getCountPreviousReservations),
      call(ReservationsAPI.getSumPreviousGuestsHosted),
      call(ReservationsAPI.getCountPreviousOwnersBlocks),
    ]);
    const [
      NightsBooked,
      Reservations,
      GuestsHosted,
      OwnersBlocks,
    ] = data;

    const basePayload: {[key: string] : object} = {
      indicators: {
        NightsBooked,
        Reservations,
        GuestsHosted,
        OwnersBlocks,
      }
    }

    yield put({
      type: RESERVATIONS_PREVIOUS_INDICATORS_SUCCESS,
      payload: { ...basePayload }
    });
  } catch (error: any) {
    yield put({
      type: RESERVATIONS_PREVIOUS_INDICATORS_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherPreviousReservationsIndicators() {
  yield takeLatest(
    RESERVATIONS_PREVIOUS_INDICATORS_REQUEST,
    workerPreviousReservationsIndicators
  );
}
