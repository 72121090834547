import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';


import { isMobileModeSelector } from '../../../../../../../../redux/selectors/appStatusSelector';
import DropDownList from '../DropDownList/DropDownList';
import { LeftChevron } from '../../../../../../../../assets/icons/LeftChevron';
import { RightChevron } from '../../../../../../../../assets/icons/RightChevron';
import { shortMonthsNames } from '../../../../../../../../utils/constants/periodLists';
import { yearsRange } from '../../../../../../../../utils/commonUtils';
import { addMonths, subtractMonths } from '../../../../../../../../utils/dateUtils';
import classes from './CustomHeader.module.scss';

const CustomHeader = ({
                        changeYear,
                        customHeaderCount,
                        decreaseMonth,
                        increaseMonth,
                        monthDate,
                        cb,
                        minDate,
  minYear = 2019,
  maxYear = new Date().getFullYear() + 1,
                      }: any) => {
  const isMobileView = useSelector(isMobileModeSelector);

  const years = minDate ? yearsRange(minDate.getFullYear()) : yearsRange();

  const leftOffset = subtractMonths(monthDate, 1)?.getFullYear();
  const rightOffset = addMonths(monthDate, 1)?.getFullYear();

  const isLeftBtnDisabled = leftOffset && leftOffset < minYear;
  const isRightBtnDisabled = rightOffset && rightOffset > maxYear;

  useEffect(() => {
    if (minDate && minDate.getFullYear() > monthDate.getFullYear()) {
      changeYear(minDate.getFullYear());
    }
  }, [minDate])

  return <div className={classes.datePickerHeader}>
    {customHeaderCount === 0 && (
      <div className={`${classes.chevronLeft} ${isLeftBtnDisabled ? classes.disabled : ''}`} onClick={() => {
        decreaseMonth()
        cb();
      }}>
        <LeftChevron />
      </div>
    )}

    <div className={classes.datePickerHeader__content}>
      <div className={classes.datePickerHeader__month}>
        {shortMonthsNames[monthDate.getMonth()]}
      </div>

      <DropDownList
        options={years}
        value={monthDate.getFullYear()}
        onChange={(value) => changeYear(value)}
      />
    </div>

    {(isMobileView || customHeaderCount === 1) && (
      <div className={`${classes.chevronRight} ${isRightBtnDisabled ? classes.disabled : ''}`} onClick={() => {
        increaseMonth();
        cb();
      }}>
        <RightChevron />
      </div>
    )}
  </div>
}

export default CustomHeader;
