import { call, put, takeLatest } from "redux-saga/effects";
import { UserDataAPI } from "../../../api/userDataAPI";
import {
  SAVE_ACCOUNT_SETTINGS_ERROR,
  SAVE_ACCOUNT_SETTINGS_REQUEST,
  SAVE_ACCOUNT_SETTINGS_SUCCESS
} from "../../actions";

function* workerSaveSettings(action: {
  type: string;
  payload: {
    name: string;
    last_name: string;
    date_of_birth: string;
    email: string;
    nationality: string;
    phone: string;
  };
}): Generator<any> {
  try {
    yield call(UserDataAPI.saveSettings, action.payload);
    yield put({
      type: SAVE_ACCOUNT_SETTINGS_SUCCESS
    });
  } catch (error: any) {
    yield put({
      type: SAVE_ACCOUNT_SETTINGS_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherSaveSettings() {
  yield takeLatest(SAVE_ACCOUNT_SETTINGS_REQUEST, workerSaveSettings);
}
