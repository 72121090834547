import { useState } from "react";
import { useSelector } from "react-redux";
import classes from "./ContactPage.module.scss";
import {
  sendContactsErrorSelector,
  sendContactsLoadingSelector
} from "../../../redux/selectors/userDataSelector";
import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import { Toast } from "../../common/Toast/Toast";
import { ContactForm } from "./components/ContactForm/ContactForm";
import { ContactInfo } from "./components/ContactInfo/ContactInfo";
import {LeaveUsAMessage} from "./components/LeaveUsAMessage/LeaveUsAMessage";
import {isMobileModeSelector} from "../../../redux/selectors/appStatusSelector";

export const ContactPage = () => {
  const isLoading = useSelector(sendContactsLoadingSelector);
  const isError = useSelector(sendContactsErrorSelector);
  const [submitted, setSubmitted] = useState(false);
  const isMobileView = useSelector(isMobileModeSelector);

  return (
    <FullPageContainer>
      <>
        {submitted && !isLoading && !isError && (
          <Toast
            className={classes.toast}
            type="success"
            successMessage="Account information successfully saved"
          />
        )}
        {submitted && !isLoading && isError && (
          <Toast className={classes.toast} errorMessage={isError} type="error" />
        )}
        <div className={classes.card}>
          {isMobileView ? <LeaveUsAMessage/> : null}
          {!isMobileView ? <ContactInfo /> : null}
          <ContactForm
            isError={isError}
            isLoading={isLoading}
            submitted={submitted}
            setSubmitted={setSubmitted}
          />
          {isMobileView ? <ContactInfo /> : null}
        </div>
      </>
    </FullPageContainer>
  );
};
