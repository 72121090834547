import { updateState } from "../../utils/reduxUtils";
import { IReduxAction } from "../../views/interfaces";
import { GET_RESERVATIONS_DATA_SUCCESS } from "../actions";

const initialState = {
  reservations: null,
  reservationsMap: {
    previous: null,
    upcoming: null
  }
};

export const reservationsReducer = (
  state = initialState,
  { type, payload }: IReduxAction
) => {
  switch (type) {
    case GET_RESERVATIONS_DATA_SUCCESS: {
      return updateState(state, payload);
    }
    default: {
      return state;
    }
  }
};
