import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { isMobileModeSelector } from '../../../redux/selectors/appStatusSelector';
import { loginErrorSelector, loginLoadingSelector } from '../../../redux/selectors/loginSelector';
import { getAuthToken } from '../../../utils/apiUtils';
import { Logo } from '../../common/Logo/Logo';
import { Toast } from '../../common/Toast/Toast';
import { LoginForm } from './components/LoginForm/LoginForm';
import classes from './LoginPage.module.scss';

export const LoginPage = () => {
  const token = getAuthToken();
  const isMobileView = useSelector(isMobileModeSelector);
  const isLoading = useSelector(loginLoadingSelector);
  const isError = useSelector(loginErrorSelector);

  return <div className={isMobileView ? classes.mobileContainer : classes.container}>
    {token && <Navigate to="/perfomance" replace />}
    {!isLoading && isError && <Toast className={classes.toast} errorMessage={isError} type="error" />}
    {/*<Logo className={classes.logo} mobile={false} />*/}
    <LoginForm isLoading={isLoading} />
  </div>;
}
