import { call, put, takeLatest } from "redux-saga/effects";
import { UserLoginAPI } from "../../../api/loginUserAPI";
import {
  LOGOUT_USER_ERROR,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
} from "../../actions";

function* workerLogoutUser(action: {
  type: string;
  payload: { navigate: Function };
}): Generator<any> {
  try {
    const { navigate } = action.payload;
    yield sessionStorage.removeItem("AH_JWT_TOKEN");
    yield put({
      type: LOGOUT_USER_SUCCESS,
      payload: { id: null, property_id: null, userData: null, listings: null },
    });
    yield navigate("/login");
  } catch (error: any) {
    yield put({ type: LOGOUT_USER_ERROR, payload: { message: error.message } });
  }
}

export function* watcherLogoutUser() {
  yield takeLatest(LOGOUT_USER_REQUEST, workerLogoutUser);
}
