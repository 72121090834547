import { requestHandler, getServiceURL } from "../utils/apiUtils";

export class UserDataAPI {
  static get getUserDataServiceName() {
    return "GET_USER_DATA";
  }

  static get getUserLogoServiceName() {
    return "GET_USER_LOGO";
  }

  static get getListingsServiceName() {
    return "GET_LISTINGS";
  }

  static get saveSettingsServiceName() {
    return "SAVE_ACCOUNT_SETTINGS";
  }

  static async getUserData(): Promise<object> {
    const url = getServiceURL(UserDataAPI.getUserDataServiceName);
    const response = await requestHandler(url);
    return response?.data?.UserInfo;
  }

  static async getUserLogo(): Promise<object> {
    const url = getServiceURL(UserDataAPI.getUserLogoServiceName);
    const response = await requestHandler(url);
    return response?.data?.data;
  }

  static async getListings(): Promise<object> {
    const url = getServiceURL(UserDataAPI.getListingsServiceName);
    const response = await requestHandler(url);
    return response?.data?.data;
  }

  static async saveSettings(data: {
    name: string;
    last_name: string;
    date_of_birth: string;
    nationality: string;
    email: string;
    phone: string;
  }): Promise<object> {
    const url = getServiceURL(UserDataAPI.saveSettingsServiceName);
    const response = await requestHandler(url, data);
    return response?.data?.data;
  }
}
