import classes from "./CalendarHeader.module.scss";
import { SwitchControl } from "../../../../../../common/SwitchControl/SwitchControl";
import { LeftChevron } from "../../../../../../../assets/icons/LeftChevron";
import { RightChevron } from "../../../../../../../assets/icons/RightChevron";
import {
  nextMonth,
  nextYear,
  previousMonth,
  previousYear,
} from "../../../../../../../utils/calendarUtils";
import {
  longMonthsNames,
  periodList,
  shortMonthsNames,
} from "../../../../../../../utils/constants/periodLists";
import {
  SHOW_MONTH,
  SHOW_YEAR,
} from "../../../../../../../utils/constants/commonConstants";
import { useSelector } from "react-redux";
import { isMobileModeSelector } from "../../../../../../../redux/selectors/appStatusSelector";
import { Link } from "react-router-dom";
import React from "react";
import { format, getYear } from "date-fns";
import { PeriodDropdown } from "../../../../../../common/PeriodDropdown/PeriodDropdown";
import { FormControl, MenuItem, Select } from "@mui/material";
import { ChevronDownIcon } from "../../../../../../../assets/icons/ChevronDownIcon";

interface ICalendarHeader {
  currentDate: any;
  currentMode: string;
  monthtoYearSwitch: Function;
  onlyOneMonth: boolean;
  setCurrentDate: Function;
  yearList: { id: string; label: string }[];
  mode: string;
  setMode: Function;
}

export const CalendarHeader = ({
  currentDate,
  currentMode,
  monthtoYearSwitch,
  onlyOneMonth,
  setCurrentDate,
  yearList,
  mode,
  setMode,
}: ICalendarHeader) => {
  const monthMode = currentMode === SHOW_MONTH;
  const isMobileView = useSelector(isMobileModeSelector);

  return (
    <>
      {onlyOneMonth ? (
        <div className={classes.calendarHeader}>
          <div className={classes.smallDate}>{`${
            longMonthsNames[currentDate.getMonth()]
          } ${currentDate.getFullYear()}`}</div>
          <div className={classes.headerControls}>
            <LeftChevron
              onClick={() => {
                const previous = monthMode
                  ? previousMonth(currentDate)
                  : previousYear(currentDate);
                setCurrentDate(previous);
              }}
            />
            <RightChevron
              onClick={() => {
                const next = monthMode
                  ? nextMonth(currentDate)
                  : nextYear(currentDate);
                setCurrentDate(next);
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className={`${classes.calendarHeader} ${
            isMobileView &&
            currentMode === SHOW_YEAR &&
            classes.mobileYearHeader
          }`}
        >
          {isMobileView && currentMode === SHOW_YEAR ? (
            <>
              <FormControl>
                <Select
                  autoWidth={false}
                  className={classes.dropdown}
                  classes={{
                    standard: classes.standardSelect,
                    icon: classes.icon,
                  }}
                  displayEmpty
                  id="period-dropdown"
                  MenuProps={{
                    classes: { paper: classes.selectMenu },
                  }}
                  onChange={(event) =>
                    setCurrentDate(new Date(+event.target.value, 1, 1))
                  }
                  value={String(currentDate.getFullYear())}
                  variant="standard"
                  IconComponent={ChevronDownIcon}
                >
                  {yearList.map(({ id, label }) => (
                    <MenuItem key={id} value={id} className={classes.menuItem}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ) : (
            <div className={classes.headerControls}>
              <LeftChevron
                onClick={() => {
                  const previous = monthMode
                    ? previousMonth(currentDate)
                    : previousYear(currentDate);
                  setCurrentDate(previous);
                }}
              />
              <div className={classes.date}>
                {monthMode
                  ? isMobileView
                    ? longMonthsNames[currentDate.getMonth()]
                    : `${
                        shortMonthsNames[currentDate.getMonth()]
                      } ${currentDate.getFullYear()}`
                  : currentDate.getFullYear()}
              </div>
              <RightChevron
                onClick={() => {
                  const next = monthMode
                    ? nextMonth(currentDate)
                    : nextYear(currentDate);
                  setCurrentDate(next);
                }}
              />
            </div>
          )}
          {isMobileView && currentMode === SHOW_MONTH && (
            <div
              className={classes.linkToYear}
              onClick={() => monthtoYearSwitch(SHOW_YEAR)}
            >
              <LeftChevron />
              <h4>{getYear(currentDate)}</h4>
            </div>
          )}
          {!isMobileView && (
            <SwitchControl
              left={SHOW_MONTH}
              right={SHOW_YEAR}
              active={mode}
              handleSwitch={setMode}
            />
          )}
        </div>
      )}
    </>
  );
};
