import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { IMenuItem, IMenuList } from "../../interfaces";
import classes from "./MenuList.module.scss";
import { LogoutIcon } from "../../../assets/icons/LogoutIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT_USER_REQUEST } from "../../../redux/actions";
import { isMobileModeSelector } from "../../../redux/selectors/appStatusSelector";

export const MenuList = ({ activePath, list, navigate }: IMenuList) => {
  const isMobileView = useSelector(isMobileModeSelector);
  const navigateDOM = useNavigate();
  const dispatch = useDispatch();
  
  const handleLogout = () => {
    dispatch({
      type: LOGOUT_USER_REQUEST,
      payload: { navigateDOM },
    });
  };

  return (
    <List>
      {list.map(({ id, label, icon, url }: IMenuItem) => {
        const isActive = activePath === url || activePath.includes(url) ;
        return (
          <ListItem className={classes.menuItem} disablePadding key={id}>
            <ListItemButton
              className={isActive ? classes.activeMenuButton : ""}
              onClick={() => navigate(url)}
              onTouchEnd={() => navigate(url)}
            >
              <ListItemIcon
                className={
                  isActive ? classes.activeMenuIcon : classes.menuItemIcon
                }
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                className={
                  isActive ? classes.activeItemLabel : classes.menuItemLabel
                }
                primary={label}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
      {isMobileView ? (
        <ListItem className={classes.menuItem} disablePadding key="logout_key_id">
          <ListItemButton
            onClick={handleLogout}
            onTouchEnd={handleLogout}
          >
            <ListItemIcon
              className={classes.menuItemIcon}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              className={classes.menuItemLabel}
              primary="Logout"
            />
          </ListItemButton>
        </ListItem>
      ) : null}
    </List>
  );
};
