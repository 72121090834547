import { all, call, put, takeLatest } from "redux-saga/effects";
import { ReservationsAPI } from "../../../api/reservationsAPI"
import {
  RESERVATIONS_INDICATORS_SUCCESS,
  RESERVATIONS_INDICATORS_REQUEST,
  RESERVATIONS_INDICATORS_ERROR
} from "../../actions";

function* workerReservationsIndicators(action: {
  type: string;
  payload: { param: string };
}): Generator<any> {
  try {
    const params = action.payload;
    const data: any = yield all([
      call(ReservationsAPI.getSumUpcomingNightsBooked),
      call(ReservationsAPI.getCountUpcomingReservations),
      call(ReservationsAPI.getSumUpcomingGuestsHosted),
      call(ReservationsAPI.getCountUpcomingOwnersBlocks)
    ]);
    const [
      NightsBooked,
      Reservations,
      GuestsHosted,
      OwnersBlocks
    ] = data;

    const basePayload: {[key: string] : object} = {
      indicators: {
        NightsBooked,
        Reservations,
        GuestsHosted,
        OwnersBlocks
      }
    }

    yield put({
      type: RESERVATIONS_INDICATORS_SUCCESS,
      payload: { ...basePayload }
    });
  } catch (error: any) {
    yield put({
      type: RESERVATIONS_INDICATORS_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherReservationsIndicators() {
  yield takeLatest(
    RESERVATIONS_INDICATORS_REQUEST,
    workerReservationsIndicators
  );
}
