export const StarIcon = () => (
  <svg
    width="32"
    height="29"
    viewBox="0 0 32 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0483 0.951054C15.3463 0.0269704 16.6537 0.0269717 16.9517 0.951055L19.7964 9.77167C19.9299 10.1855 20.3155 10.4657 20.7503 10.4647L30.0183 10.4445C30.9892 10.4423 31.3933 11.6858 30.6065 12.2548L23.0967 17.686C22.7443 17.9408 22.597 18.3941 22.7323 18.8073L25.6155 27.6154C25.9176 28.5382 24.8599 29.3067 24.0756 28.7343L16.5896 23.2703C16.2383 23.0139 15.7617 23.0139 15.4104 23.2703L7.92442 28.7343C7.14015 29.3067 6.08243 28.5382 6.38449 27.6154L9.26771 18.8073C9.40299 18.3941 9.2557 17.9408 8.90335 17.686L1.3935 12.2548C0.606739 11.6858 1.01075 10.4423 1.9817 10.4445L11.2497 10.4647C11.6845 10.4657 12.0701 10.1855 12.2036 9.77167L15.0483 0.951054Z"
      fill="#FFE074"
    />
  </svg>
);
