export const MenuIcon = () => (
  <svg
    width="28"
    height="21"
    viewBox="0 0 28 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 19H26M2 10.5H26M2 2H26"
      stroke="#02094F"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
