import { CalendarIcon } from "../../../assets/icons/CalendarIcon";
import { DocumentIcon } from "../../../assets/icons/DocumentIcon";
import { ListingsIcon } from "../../../assets/icons/ListingsIcon";
import { OccupancyIcon } from "../../../assets/icons/OccupancyIcon";
import { PerfomanceIcon } from "../../../assets/icons/PerfomanceIcon";
import { RatingIcon } from "../../../assets/icons/RatingIcon";
import { RevenueIcon } from "../../../assets/icons/RevenueIcon";
//import { SettingsIcon } from "../../../assets/icons/SettingsIcon";
//import { ContactIcon } from "../../../assets/icons/ContactIcon";

export const mainMenuItems = [
  {
    id: "perfomance",
    label: "Performance",
    icon: <PerfomanceIcon />,
    url: "/perfomance"
  },
  {
    id: "occupancy",
    label: "Occupancy",
    icon: <OccupancyIcon />,
    url: "/occupancy"
  },
  {
    id: "revenue",
    label: "Revenue",
    icon: <RevenueIcon />,
    url: "/revenue"
  },
  {
    id: "rating",
    label: "Rating",
    icon: <RatingIcon />,
    url: "/rating"
  },
  {
    id: "calendar",
    label: "Calendar",
    icon: <CalendarIcon />,
    url: "/calendar"
  },
  {
    id: "listings",
    label: "Listings",
    icon: <ListingsIcon />,
    url: "/listings"
  },
  {
    id: "documents",
    label: "Documents",
    icon: <DocumentIcon />,
    url: "/documents"
  }
];

export const additionalMenuItems = [
  // {
  //   id: "listings",
  //   label: "Listings",
  //   icon: <ListingsIcon />,
  //   url: "/listings"
  // },
  // {
  //   id: "documents",
  //   label: "Documents",
  //   icon: <DocumentIcon />,
  //   url: "/documents"
  // },
 
  /*{
    id: "settings",
    label: "Settings",
    icon: <SettingsIcon />,
    url: "/settings"
  },
  */
  /*
  {
    id: "contacts",
    label: "Contacts",
    icon: <ContactIcon />,
    url: "/contacts"
  }
  */
];
