import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WINDOW_RESIZE } from "../../../redux/actions";
import { isMobileModeSelector } from "../../../redux/selectors/appStatusSelector";

export const MobileDetector = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(isMobileModeSelector);

  useEffect(() => {
    if (window.matchMedia("(max-width: 768px)").matches) {
      dispatch({
        type: WINDOW_RESIZE,
        payload: { isMobileMode: true }
      });
    }
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches && !isMobile) {
        dispatch({
          type: WINDOW_RESIZE,
          payload: { isMobileMode: true }
        });
      } else if (window.matchMedia("(min-width: 768px)").matches && isMobile) {
        dispatch({
          type: WINDOW_RESIZE,
          payload: { isMobileMode: false }
        });
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  });

  return null;
};
