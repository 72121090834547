export const ExternalLinkActive = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1907_13013)">
      <path
        d="M11.3333 5.33333C11.1565 5.33333 10.987 5.40357 10.8619 5.5286C10.7369 5.65362 10.6667 5.82319 10.6667 6V10C10.6667 10.1768 10.5964 10.3464 10.4714 10.4714C10.3464 10.5964 10.1768 10.6667 10 10.6667H2C1.82319 10.6667 1.65362 10.5964 1.5286 10.4714C1.40357 10.3464 1.33333 10.1768 1.33333 10V2C1.33333 1.82319 1.40357 1.65362 1.5286 1.5286C1.65362 1.40357 1.82319 1.33333 2 1.33333H6C6.17681 1.33333 6.34638 1.2631 6.4714 1.13807C6.59643 1.01305 6.66667 0.843478 6.66667 0.666667C6.66667 0.489856 6.59643 0.320286 6.4714 0.195262C6.34638 0.0702379 6.17681 0 6 0H2C1.46957 0 0.960859 0.210714 0.585787 0.585787C0.210714 0.960859 0 1.46957 0 2V10C0 10.5304 0.210714 11.0391 0.585787 11.4142C0.960859 11.7893 1.46957 12 2 12H10C10.5304 12 11.0391 11.7893 11.4142 11.4142C11.7893 11.0391 12 10.5304 12 10V6C12 5.82319 11.9298 5.65362 11.8047 5.5286C11.6797 5.40357 11.5101 5.33333 11.3333 5.33333Z"
        fill="#5D6293"
      />
      <path
        d="M8.6663 1.33333H9.71963L5.5263 5.52C5.46381 5.58198 5.41422 5.65571 5.38037 5.73695C5.34653 5.81819 5.3291 5.90533 5.3291 5.99333C5.3291 6.08134 5.34653 6.16848 5.38037 6.24972C5.41422 6.33096 5.46381 6.40469 5.5263 6.46667C5.58828 6.52915 5.66201 6.57875 5.74325 6.61259C5.82449 6.64644 5.91163 6.66387 5.99963 6.66387C6.08764 6.66387 6.17478 6.64644 6.25602 6.61259C6.33726 6.57875 6.41099 6.52915 6.47297 6.46667L10.6663 2.28V3.33333C10.6663 3.51014 10.7365 3.67971 10.8616 3.80474C10.9866 3.92976 11.1562 4 11.333 4C11.5098 4 11.6793 3.92976 11.8044 3.80474C11.9294 3.67971 11.9996 3.51014 11.9996 3.33333V0.666667C11.9996 0.489856 11.9294 0.320286 11.8044 0.195262C11.6793 0.0702379 11.5098 0 11.333 0H8.6663C8.48949 0 8.31992 0.0702379 8.1949 0.195262C8.06987 0.320286 7.99963 0.489856 7.99963 0.666667C7.99963 0.843478 8.06987 1.01305 8.1949 1.13807C8.31992 1.2631 8.48949 1.33333 8.6663 1.33333Z"
        fill="#5D6293"
      />
    </g>
    <defs>
      <clipPath id="clip0_1907_13013">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
