import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { ForgotPassword } from "../../../../../assets/icons/ForgotPassword";
import classes from "./ForgotPasswordModal.module.scss";

export const ForgotPasswordModal = (props: {
  open: boolean;
  handleClose: Function;
  handleChangePassword: Function;
}) => {
  const { open, handleClose, handleChangePassword } = props;

  return (
    <Dialog
      classes={{ paper: classes.modalContainer }}
      open={open}
      onClose={() => handleClose()}
    >
      <DialogContent className={classes.modalBody}>
        <ForgotPassword className={classes.lockIcon} />
        <h2>Are you sure you want to reset your password?</h2>
        <p className={classes.info}>
          If you forgot your password or wish to change your password please contact your Property Manager.
        </p>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          className="buttonSecondary"
          color="secondary"
          onClick={() => handleClose()}
          size="large"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleChangePassword()}
          size="large"
          variant="contained"
        >
          Reset password
        </Button>
      </DialogActions>
    </Dialog>
  );
};
