import { FormControl, MenuItem, Select } from "@mui/material";
import classes from "./PeriodDropdown.module.scss";
import { IPeriodDropdown } from "../../interfaces";

export const PeriodDropdown = ({
  className,
  period,
  periodList,
  setPeriod,
  variant
}: IPeriodDropdown) => {
  return (
    <FormControl>
      <Select
        autoWidth={false}
        className={className}
        classes={{
          outlined: classes.outlinedSelect,
          standard: classes.standardSelect
        }}
        displayEmpty
        id="period-dropdown"
        MenuProps={{
          classes: { paper: classes.selectMenu }
        }}
        onChange={event => setPeriod(event.target.value)}
        value={period}
        variant={variant || "outlined"}
      >
        {periodList.map(({ id, label }) => (
          <MenuItem key={id} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
