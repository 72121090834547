export const PerfomanceIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.66667C0 1.95942 0.280951 1.28115 0.781048 0.781048C1.28115 0.280951 1.95942 0 2.66667 0H21.3333C22.0406 0 22.7189 0.280951 23.219 0.781048C23.719 1.28115 24 1.95942 24 2.66667V21.3333C24 22.0406 23.719 22.7189 23.219 23.219C22.7189 23.719 22.0406 24 21.3333 24H2.66667C1.95942 24 1.28115 23.719 0.781048 23.219C0.280951 22.7189 0 22.0406 0 21.3333V2.66667ZM14.5853 6.20667C14.1147 5.02933 12.428 5.096 12.0493 6.30533L9.71333 13.78L9.196 12.744C9.08414 12.5205 8.91226 12.3325 8.69962 12.2011C8.48698 12.0697 8.24196 12.0001 7.992 12H5.33333C4.97971 12 4.64057 12.1405 4.39052 12.3905C4.14048 12.6406 4 12.9797 4 13.3333C4 13.687 4.14048 14.0261 4.39052 14.2761C4.64057 14.5262 4.97971 14.6667 5.33333 14.6667H7.176L8.792 17.9C9.33467 18.984 10.9213 18.856 11.2827 17.7L13.488 10.6427L14.7587 13.82C14.9627 14.332 15.4587 14.6667 16.0093 14.6667H18.6667C19.0203 14.6667 19.3594 14.5262 19.6095 14.2761C19.8595 14.0261 20 13.687 20 13.3333C20 12.9797 19.8595 12.6406 19.6095 12.3905C19.3594 12.1405 19.0203 12 18.6667 12H16.9027L14.5853 6.20667Z"
      fill="#B4B7CF"
    />
  </svg>
);
