import { IIcon } from "../../views/interfaces";

export const PhoneIcon = ({ className }: IIcon) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9881 18.1232C23.7755 19.7461 22.9807 21.2366 21.7517 22.3176C20.5226 23.3985 18.9428 23.9964 17.306 24C7.76367 24 0 16.2363 0 6.69398C0.00360825 5.05719 0.601473 3.4774 1.68244 2.24833C2.7634 1.01926 4.25391 0.224538 5.87684 0.0119337C6.29055 -0.0339382 6.70813 0.0531664 7.069 0.260616C7.42988 0.468065 7.71529 0.785073 7.88385 1.16567L10.2995 6.80214C10.4248 7.09471 10.475 7.41395 10.4456 7.73086C10.4163 8.04777 10.3082 8.35233 10.1312 8.61687L8.13623 11.6695C9.03961 13.5028 10.5281 14.9829 12.3666 15.8758L15.3831 13.8688C15.6474 13.6906 15.9527 13.5828 16.2702 13.5555C16.5878 13.5281 16.907 13.5822 17.1979 13.7125L22.8343 16.1161C23.2149 16.2847 23.5319 16.5701 23.7394 16.931C23.9468 17.2919 24.0339 17.7094 23.9881 18.1232Z"
      fill="#5D6293"
    />
  </svg>
);
