import { FacebookIcon } from "../../../../../assets/icons/FacebookIcon";
import { InstagramIcon } from "../../../../../assets/icons/InstagramIcon";
import { LocationIcon } from "../../../../../assets/icons/LocationIcon";
import { MailIcon } from "../../../../../assets/icons/MailIcon";
import { PhoneIcon } from "../../../../../assets/icons/PhoneIcon";
import { TwitterIcon } from "../../../../../assets/icons/TwitterIcon";
import classes from "./ContactInfo.module.scss";
import {LeaveUsAMessage} from "../LeaveUsAMessage/LeaveUsAMessage";
import {useSelector} from "react-redux";
import {isMobileModeSelector} from "../../../../../redux/selectors/appStatusSelector";

export const ContactInfo = () => {
  const isMobileView = useSelector(isMobileModeSelector);
  return (
    <div className={classes.contactInfoContainer}>
      {isMobileView ? null : <LeaveUsAMessage/>}
      <div className={classes.contactsList}>
        <div className={classes.contact}>
          <a href="tel:+123456789090">
            <PhoneIcon className={classes.contactIcon} />
            +123456789090
          </a>
        </div>
        <div className={classes.contact}>
          <a href="mailto:angelhost@mail.com">
            <MailIcon className={classes.contactIcon} />
            angelhost@mail.com
          </a>
        </div>
        <div className={classes.contact}>
          <a
            href="https://goo.gl/maps/rbLFSDgDan5nFNcc6"
            rel="noreferrer"
            target="_blank"
          >
            <LocationIcon className={classes.contactIcon} />
            102 Street 274 Apartment <br /> Feastrevile Trevorse, PA 1906{" "}
          </a>
        </div>
      </div>
      <div className={classes.socialLinks}>
        <a href="https://www.facebook.com/" rel="noreferrer" target="_blank">
          <FacebookIcon className={classes.socialLink} />
        </a>
        <a href="https://www.facebook.com/" rel="noreferrer" target="_blank">
          <TwitterIcon className={classes.socialLink} />
        </a>
        <a href="https://www.facebook.com/" rel="noreferrer" target="_blank">
          <InstagramIcon className={classes.socialLink} />
        </a>
      </div>
    </div>
  );
};
