import { updateState } from "../../utils/reduxUtils";
import { IReduxAction } from "../../views/interfaces";
import { WINDOW_RESIZE } from "../actions";

const initialState = {};

export const appReducer = (state = initialState, { type, payload }: IReduxAction) => {
  switch (type) {
    case WINDOW_RESIZE: {
      return updateState(state, payload);
    }
    default:
      return state;
  }
};
