import { AppBar, IconButton, Toolbar, Tooltip } from "@mui/material";
import { Logo } from "../Logo/Logo";
// import { ListingsDropdown } from "./components/ListingsDropdown";
import classes from "./DesktopHeader.module.scss";
import { IDesktopHeader } from "../../interfaces";
import { MenuIcon } from "../../../assets/icons/MenuIcon";
import { LogoutIcon } from "../../../assets/icons/LogoutIcon";
import {LOGIN_USER_REQUEST, LOGOUT_USER_REQUEST} from "../../../redux/actions";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

export const DesktopHeader = ({
  handleMenu,
  listing,
  logo,
  open,
  userName,
}: IDesktopHeader) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch({
      type: LOGOUT_USER_REQUEST,
      payload: { navigate },
    });
  };
  return (
    <AppBar className={classes.headerContainer} position="fixed">
      <div className={classes.actionsContainer}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => handleMenu(!open)}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <Toolbar>
          <Logo className={classes.logo} mobile={false} src={logo} />
        </Toolbar>
      </div>
      <div className={classes.actionsContainer}>
        {/* <ListingsDropdown listings={listings} /> */}
        <p className={classes.userData}>{userName}</p>
        <p className={classes.userData}>{listing}</p>
        <Tooltip
          arrow
          title="Logout"
          classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        >
          <IconButton
            color="inherit"
            aria-label="logout"
            onClick={handleLogout}
            edge="end"
          >
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </div>
    </AppBar>
  );
};
