export const RevenueIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.2 0H22.8C23.1183 0 23.4235 0.140476 23.6485 0.390524C23.8736 0.640573 24 0.979711 24 1.33333V22.6667C24 23.0203 23.8736 23.3594 23.6485 23.6095C23.4235 23.8595 23.1183 24 22.8 24H1.2C0.88174 24 0.576515 23.8595 0.351472 23.6095C0.126428 23.3594 0 23.0203 0 22.6667V1.33333C0 0.979711 0.126428 0.640573 0.351472 0.390524C0.576515 0.140476 0.88174 0 1.2 0ZM7.8 14.6667V17.3333H10.8V20H13.2V17.3333H14.4C15.1957 17.3333 15.9587 16.9821 16.5213 16.357C17.0839 15.7319 17.4 14.8841 17.4 14C17.4 13.1159 17.0839 12.2681 16.5213 11.643C15.9587 11.0179 15.1957 10.6667 14.4 10.6667H9.6C9.44087 10.6667 9.28826 10.5964 9.17574 10.4714C9.06321 10.3464 9 10.1768 9 10C9 9.82319 9.06321 9.65362 9.17574 9.52859C9.28826 9.40357 9.44087 9.33333 9.6 9.33333H16.2V6.66667H13.2V4H10.8V6.66667H9.6C8.80435 6.66667 8.04129 7.01786 7.47868 7.64298C6.91607 8.2681 6.6 9.11594 6.6 10C6.6 10.8841 6.91607 11.7319 7.47868 12.357C8.04129 12.9821 8.80435 13.3333 9.6 13.3333H14.4C14.5591 13.3333 14.7117 13.4036 14.8243 13.5286C14.9368 13.6536 15 13.8232 15 14C15 14.1768 14.9368 14.3464 14.8243 14.4714C14.7117 14.5964 14.5591 14.6667 14.4 14.6667H7.8Z"
      fill="#B4B7CF"
    />
  </svg>
);
