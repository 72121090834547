import { updateSeveralEntities } from "../../utils/reduxUtils";
import { IReduxAction } from "../../views/interfaces";
import { RESERVATIONS_INDICATORS_SUCCESS } from "../actions";

const initialState = {
  NightsBooked: null,
  Reservations: null,
  GuestsHosted: null,
  OwnersBlocks: null
};

export const reservationsIndicatorsReducer = (
  state = initialState,
  { type, payload }: IReduxAction
) => {
  switch (type) {
    case RESERVATIONS_INDICATORS_SUCCESS: {
      return updateSeveralEntities(state, payload);
    }
    default:
      return state;
  }
};
