import { AppLogo } from "../../../assets/icons/DefaultAppLogo";
import { MobileLogo } from "../../../assets/icons/MobileLogo";
import { IAppLogo } from "../../interfaces";

export const Logo = ({ className, mobile, src }: IAppLogo) => {
    return src ? (
      <img alt="Angel Host logo" className={className} src={src} />
    ) : (mobile ? <MobileLogo className={className} /> :
      null
    );
  };
