export const LineIcon = () => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2827 9.00101L15.4866 9.10883L15.6104 8.91421L20.1509 1.77834L21.6099 2.54501L16.1259 11.1719L9.16849 7.52853L8.9649 7.42191L8.84156 7.61584L3.59456 15.8658L3.35023 16.25H3.80551H21.7495V17.75H0.249512V0.25H1.94951V14.54V15.401L2.41064 14.6739L8.33691 5.32899L15.2827 9.00101Z"
      fill="#B4B7CF"
      stroke="#B4B7CF"
      strokeWidth="0.5"
    />
  </svg>
);
