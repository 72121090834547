import { L12M, L30D, N30D } from "../../utils/constants/filterConstants";
import { updateOneEntity, updateSeveralEntities } from "../../utils/reduxUtils";
import { IReduxAction } from "../../views/interfaces";
import {
  OCCUPANCY_BY_YEAR_SUCCESS,
  MARKET_OCCUPANCY_SUCCESS,
  GENERAL_OCCUPANCY_SUCCESS,
  RESERVATIONS_VS_BLOCKS_OCCUPANCY_SUCCESS,
  DAILY_OCCUPANCY_SUCCESS,
  MONTHLY_OCCUPANCY_RATE_SUCCESS
} from "../actions";

const initialState = {
  marketOccupancy: null,
  generalOccupancy: null,
  yearlyOccupancy: null,
  yearlyMarketOccupancy: null,
  reservationsVsBlocks: {
    [L30D]: null,
    [L12M]: null,
    [N30D]: null
  },
  dailyOccupancy: {
    [L30D]: null,
    [L12M]: null,
    [N30D]: null
  }
};

export const occupancyReducer = (
  state = initialState,
  { type, payload }: IReduxAction
) => {
  switch (type) {
    case OCCUPANCY_BY_YEAR_SUCCESS:
    case GENERAL_OCCUPANCY_SUCCESS:
    case MARKET_OCCUPANCY_SUCCESS:
    case DAILY_OCCUPANCY_SUCCESS: {
      return updateOneEntity(state, payload);
    }
    case RESERVATIONS_VS_BLOCKS_OCCUPANCY_SUCCESS: {
      return updateOneEntity(state, payload);
    }
    case MONTHLY_OCCUPANCY_RATE_SUCCESS: {
      return updateSeveralEntities(state, payload);
    }
    default:
      return state;
  }
};
