import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
    button: {
      fontSize: "18px",
      fontWeight: 500,
      textTransform: "none"
    }
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { color: "primary" },
          style: {
            color: "#fff",
            borderRadius: "16px",
            height: "56px",
            minWidth: "208px",
            boxShadow: "none"
          }
        },
        {
          props: { color: "secondary" },
          style: {
            color: "#9798A5",
            borderColor: "#9798A5",
            borderRadius: "16px",
            height: "56px",
            minWidth: "220px",
            boxShadow: "none"
          }
        }
      ]
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: "#B4B7CF",
          borderRadius: "16px"
        },
        input: {
          paddingLeft: "30px"
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: "96px",
          background: "#FFFFFF",
          boxShadow: "none"
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: "100%"
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          marginTop: "96px",
          height: "calc(100% - 96px)",
          border: "none",
          justifyContent: "space-between"
        }
      }
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            color: "#9798A5",
            borderColor: "#F5F5FC",
            borderRadius: "8px",
            padding: "8px 0",
            height: "40px"
          }
        },
        {
          props: { variant: "standard" },
          style: {
            textAlign: "center",
            color: "#5D6293",
            border: "none",
            borderRadius: "8px",
            width: "100px",
            background: "#F5F5FC",

            "&::before": {
              border: "none"
            },

            "&::after": {
              border: "none"
            },

            "&:hover:before": {
              border: "none!important"
            }
          }
        }
      ]
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#F5F5FC"
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        }
      }
    }
  },
  palette: {
    primary: {
      main: "#00B48D"
    },
    secondary: {
      main: "#F5F5FC"
    }
  }
});
