import { requestHandler, getServiceURL } from "../utils/apiUtils";

export class DocumentsAPI {
  static get getAllDocsServiceName() {
    return "GET_DOCUMENTS";
  }

  static get downloadDocumentServiceName() {
    return "DOWNLOAD_DOCUMENT";
  }

  static async getAllDocs(): Promise<object> {
    const url = getServiceURL(DocumentsAPI.getAllDocsServiceName);
    const response = await requestHandler(url);
    return response?.data?.data;
  }

  // static async downloadDocument(params: {file_id: string}): Promise<object> {
  //   const url = getServiceURL(DocumentsAPI.downloadDocumentServiceName);
  //   const response = await requestHandler(url, params);
  //   return response?.data?.data;
  // }
}
