import {
  startOfMonth,
  startOfWeek,
  endOfMonth,
  endOfWeek,
  startOfDay,
  addDays,
  addMonths,
  getYear
} from "date-fns";

function takeWeek(start = new Date()) {
  let date = startOfWeek(startOfDay(start), { weekStartsOn: 1 });

  return function() {
    const week = [...Array(7)].map((_, i) => addDays(date, i));
    date = addDays(week[6], 1);
    return week;
  };
}

function takeMonth(start = new Date()) {
  let month: any = [];
  let date = start;

  function lastDayOfRange(range: any) {
    return range[range.length - 1][6];
  }

  return function() {
    const weekGen = takeWeek(startOfMonth(date));
    const endDate = startOfDay(endOfWeek(endOfMonth(date)));
    month.push(weekGen());

    while (lastDayOfRange(month) < endDate) {
      month.push(weekGen());
    }

    const range = month;
    month = [];
    date = addDays(lastDayOfRange(range), 1);

    return range;
  };
}

function takeYear(start = new Date()) {
  let year: any = [];
  let date = start;

  return function() {
    const monthGen = takeMonth(date);
    year.push(monthGen());
    let nextDate = addMonths(date, 1);;

    while (getYear(nextDate) === getYear(start)) {
      const monthGen = takeMonth(nextDate);
      year.push(monthGen());
      nextDate = addMonths(nextDate, 1);
    }

    return year;
  }
}

function previousMonth(date: Date) {
  const month = date.getMonth();
  const year = date.getFullYear();
  const newDate = new Date(year, month - 1, 1);
  return new Date(newDate);
}

function nextMonth(date: Date) {
  const month = date.getMonth();
  const year = date.getFullYear();
  const newDate = new Date(year, month + 1, 1);
  return new Date(newDate);
}

function previousYear(date: Date) {
  const year = date.getFullYear();
  const newDate = new Date(year - 1, 0, 1);
  return new Date(newDate);
}

function nextYear(date: Date) {
  const year = date.getFullYear();
  const newDate = new Date(year + 1, 0, 1);
  return new Date(newDate);
}

function getDates(initialDate: Date, stopDate: Date) {
  const dateArray = [];
  let initial = initialDate;
  while (initial <= stopDate) {
    dateArray.push(initial);
    initial = addDays(initial, 1);
  }

  return dateArray;
}

export { takeYear, takeMonth, takeWeek, previousMonth, nextMonth, previousYear, nextYear, getDates };
