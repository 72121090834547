import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./RevenuePerNightCard.module.scss";
import { GET_PER_NIGHT_REVENUE_REQUEST } from "../../../../../../redux/actions";
import { L12M, L3060D, N30D } from '../../../../../../utils/constants/filterConstants';
import { PeriodDropdown } from "../../../../../common/PeriodDropdown/PeriodDropdown";
import { periodList } from "../../../../../../utils/constants/periodLists";
import { Highlight } from "../../../../../common/BasicHighlight/BasicHighlight";
import { perNightRevenueSelector } from "../../../../../../redux/selectors/revenueSelector";
import { Divider } from "@mui/material";
import {isMobileModeSelector} from "../../../../../../redux/selectors/appStatusSelector";

export const RevenuePerNightCard = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(L12M);
  const previousPeriod = period === N30D ? L12M : L3060D;

  const data = useSelector(perNightRevenueSelector(period));
  const previousData = useSelector(perNightRevenueSelector(previousPeriod));

  const isMobileView = useSelector(isMobileModeSelector);
  const { general, market } = data || {};
  const { general: previousGeneral, market: previousMarket } = previousData || {};

  useEffect(() => {
    dispatch({
      type: GET_PER_NIGHT_REVENUE_REQUEST,
      payload: { param: period }
    });
  }, [dispatch, period]);

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardHeader}>
        <h4>Revenue per available night</h4>
        <PeriodDropdown
          className={classes.dropdown}
          period={period}
          periodList={periodList}
          setPeriod={setPeriod}
        />
      </div>
      <div className={classes.cardBody}>
        {general?.PerNightRevenue && <Highlight
          className={classes.highlight}
          label="Revenue per available night:"
          prependSymbol={"$"}
          reverse
          tooltip={isMobileView ? undefined :
            "This is the Revenue received Per Available Night. This number is calculated by taking into account both the Average Rate at which the property was Booked (ADR) and the Number of Nights it was Booked and subtracting the Owner Nights. "
          }
          value={general?.PerNightRevenue}
          previousValue={general?.last30_60_days_PerNightRevenue}
					showDynamicIndicator={period !== L12M && period !== N30D}
        />}
        {!isMobileView ? <Divider orientation="vertical" /> : null}
        {market?.Market_RevPan && <Highlight
          className={classes.highlight}
          label="Market Revenue per available night:"
          prependSymbol={"$"}
          reverse
          tooltip={isMobileView ? undefined :
            "This is the Revenue received Per Available Night. This number is calculated by taking into account both the Average Rate at which the property was Booked (ADR) and the Number of Nights it was Booked and subtracting the Owner Nights. " //TODO: change tooltip text
          }
          value={market?.Market_RevPan}
          previousValue={market?.l30_60d_Market_RevPan}
					showDynamicIndicator={period !== L12M && period !== N30D}
        />}
      </div>
    </div>
  );
};
