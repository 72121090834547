import { all, call, put, takeLatest } from "redux-saga/effects";
import { UserDataAPI } from "../../../api/userDataAPI";
import {
  USER_DATA_ERROR,
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_LOGO_ERROR, USER_LOGO_REQUEST,
  USER_LOGO_SUCCESS
} from '../../actions';

function* workerUserData(): Generator<any> {
  try {
    const data: any = yield all([
      call(UserDataAPI.getUserData),
      call(UserDataAPI.getListings)
    ]);
    const [userData, listings] = data;
    yield put({
      type: USER_DATA_SUCCESS,
      payload: { userData, listings }
    });
  } catch (error: any) {
    yield put({
      type: USER_DATA_ERROR,
      payload: { message: error.message }
    });
  }
}


function* workerUserLogo(): Generator<any> {
  try {
    const data: any = yield call(UserDataAPI.getUserLogo);

    yield put({
      type: USER_LOGO_SUCCESS,
      payload: {
        userLogo: data,
      }
    });
  } catch (error: any) {
    yield put({
      type: USER_LOGO_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherUserData() {
  yield takeLatest(USER_DATA_REQUEST, workerUserData);
  yield takeLatest(USER_LOGO_REQUEST, workerUserLogo);
}
