import { IconButton } from "@mui/material";
import { IMenuItem, IMenuList } from "../../interfaces";
import classes from "./MobileFooterMenu.module.scss";

export const MobileFooterMenu = ({ activePath, list, navigate }: IMenuList) => {
  return (
    <div className={classes.mobileFooterContainer}>
      {list.map(({ id, icon, url }: IMenuItem) => {
        const isActive = activePath === url;
        return (
          <IconButton className={isActive ? classes.activeButton : ""} key={id} onClick={() => navigate(url)}>
            {icon}
          </IconButton>
        );
      })}
    </div>
  );
};
