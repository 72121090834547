import { IIcon } from "../../views/interfaces";

export const FacebookIcon = ({ className }: IIcon) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="4 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 12.0301C28 5.38947 22.624 0 16 0C9.376 0 4 5.38947 4 12.0301C4 17.8526 8.128 22.7007 13.6 23.8195V15.6391H11.2V12.0301H13.6V9.02256C13.6 6.70075 15.484 4.81203 17.8 4.81203H20.8V8.42105H18.4C17.74 8.42105 17.2 8.96241 17.2 9.62406V12.0301H20.8V15.6391H17.2V24C23.26 23.3985 28 18.2737 28 12.0301Z"
      fill="#B4B7CF"
    />
  </svg>
);
