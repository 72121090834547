import { createErrorSelector, createLoadingSelector } from "../../utils/reduxUtils";
import { SAVE_ACCOUNT_SETTINGS, SEND_CONTACTS } from "../actions";

export const userDataSelector = (state: any) => state.user.userData;

export const userLogoSelector = (state: any) => state.user.userLogo;

export const userListingsSelector = (state: any) => state.user.listings;

export const propertyIdSelector = (state: any) => state.user.property_id;

export const saveSettingsLoadingSelector = createLoadingSelector([SAVE_ACCOUNT_SETTINGS]);
export const saveSettingsErrorSelector = createErrorSelector([SAVE_ACCOUNT_SETTINGS]);

export const sendContactsLoadingSelector = createLoadingSelector([SEND_CONTACTS]);
export const sendContactsErrorSelector = createErrorSelector([SEND_CONTACTS]);
