export const ExpediaIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.39271 21.0896C0.861413 17.918 0.581623 14.2858 1.60912 10.9171C2.63662 7.54841 4.89592 4.69066 7.93662 2.91358C10.9773 1.1365 14.5761 0.570631 18.0154 1.32878C21.4548 2.08692 24.4821 4.11339 26.4938 7.0042M27.6459 8.99184C29.1618 12.1833 29.415 15.83 28.3547 19.2003C27.2944 22.5707 24.999 25.4156 21.9289 27.1643C18.8588 28.9131 15.241 29.4364 11.8012 28.6294C8.36142 27.8223 5.35393 25.7446 3.38197 22.8128"
      stroke="#5D6293"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.4938 7.0042L19.5234 10.4266L9.69788 5.51216L8.01372 6.42718L14.6455 11.7525L15.3801 12.7763L11.7969 14.8219L2.39271 21.0896M27.6459 8.99184L21.1887 13.3702L20.5732 24.3453L18.8578 25.2472L17.6269 16.8669L17.0981 15.7447L13.3156 17.9447L3.38197 22.8128"
      stroke="#5D6293"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
