import { requestHandler, getServiceURL } from "../utils/apiUtils";
import { IOccupancyAPI } from "../views/interfaces";

export class OccupancyRateAPI {
  static get getOccupancyServiceName() {
    return "GET_OCCUPANCY_RATE";
  }

  static get getMarketOccupancyServiceName() {
    return "GET_MARKET_OCCUPANCY_RATE";
  }

  static get getYearlyOccupancyRateServiceName() {
    return "GET_YEARLY_OCCUPANCY_RATE";
  }

  static get getYearlyMarketOccupancyServiceName() {
    return "GET_YEARLY_MARKET_OCCUPANCY";
  }

  static get getPaidReservationsOccupancyServiceName() {
    return "GET_PAID_RESERVATIONS_OCCUPANCY";
  }

  static get getOwnerBlocksOccupancyServiceName() {
    return "GET_OWNER_BLOCKS_OCCUPANCY";
  }

  static get getDailyOccupancyServiceName() {
    return "GET_DAILY_OCCUPANCY";
  }

  static async getOccupancy({ param }: IOccupancyAPI): Promise<object> {
    const url = getServiceURL(OccupancyRateAPI.getOccupancyServiceName);
    const response = await requestHandler(url, { param });
    return response?.data?.OccupancyRate;
  }

  static async getMarketOccupancy({ param }: IOccupancyAPI): Promise<object> {
    const url = getServiceURL(OccupancyRateAPI.getMarketOccupancyServiceName);
    const response = await requestHandler(url, { param });
    return response?.data;
  }

  static async getYearlyOccupancyRate({ year = "2022" }: IOccupancyAPI): Promise<object> {
    const url = getServiceURL(
      OccupancyRateAPI.getYearlyOccupancyRateServiceName
    );
    const response = await requestHandler(url, { year });
    return response?.data?.OccupancyRate;
  }

  static async getYearlyMarketOccupancyRate({ year = "2022" }: IOccupancyAPI): Promise<object> {
    const url = getServiceURL(
      OccupancyRateAPI.getYearlyMarketOccupancyServiceName
    );
    const response = await requestHandler(url, { year });
    return response?.data?.MarketOccupancyRate;
  }

  static async getPaidReservationsRate({ param }: IOccupancyAPI): Promise<object> {
    const url = getServiceURL(OccupancyRateAPI.getPaidReservationsOccupancyServiceName);
    const response = await requestHandler(url, { param });
    return response?.data?.PaidReservationOccupancy;
  }

  static async getOwnerBlocksRate({ param }: IOccupancyAPI): Promise<any> {
    const url = getServiceURL(OccupancyRateAPI.getOwnerBlocksOccupancyServiceName);
    const response = await requestHandler(url, { param });
    return response?.data?.OwnerOccupancyRate[0]?.Occupancy;
  }

  static async getDailyOccupancyRate({ param }: IOccupancyAPI): Promise<object>  {
    const url = getServiceURL(OccupancyRateAPI.getDailyOccupancyServiceName);
    const response = await requestHandler(url, { param });
    return response?.data?.DailyOccupancyRate;
  }
}
