import { IIcon } from "../../views/interfaces";

export const MobileLogo = ({ className }: IIcon) => (
  <svg
    className={className}
    width="143"
    height="19"
    viewBox="0 0 143 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1729_7625)">
      <path
        d="M6.30171 0.143677H7.76374L14.0738 14.523H12.3629L10.7408 10.7491H3.24015L1.59537 14.523H0L6.30171 0.143677ZM10.1532 9.30283L7.02261 2.02353L3.85151 9.30283H10.1532Z"
        fill="#5D6293"
      />
      <path
        d="M18.7712 0.247437H20.2332L28.9506 11.6863V0.247437H30.4728V14.5235H29.2227L20.2934 2.81635V14.5235H18.7712V0.247437Z"
        fill="#5D6293"
      />
      <path
        d="M35.7268 7.4298V7.38865C35.7268 3.46927 38.5169 0.00617953 42.669 0.00617953C44.5614 -0.0726913 46.4101 0.605894 47.829 1.90016L46.8408 3.12842C45.7007 2.0319 44.1865 1.4451 42.6285 1.49606C39.5646 1.49606 37.3686 4.16815 37.3686 7.34996V7.39111C37.3686 10.7934 39.4848 13.3205 42.8452 13.3205C44.2968 13.3187 45.7063 12.817 46.8503 11.8952V8.34609H42.6291V6.89981H48.3368V12.5885C46.8068 13.9883 44.8342 14.7638 42.7893 14.7693C38.4371 14.7693 35.7268 11.507 35.7268 7.4298Z"
        fill="#5D6293"
      />
      <path
        d="M53.8366 0.247437H63.8375V1.72135H55.3987V6.59019H62.9487V8.05735H55.3987V13.057H63.9387V14.5242H53.8366V0.247437Z"
        fill="#5D6293"
      />
      <path
        d="M69.0498 0.247437H70.6118V13.0361H78.4018V14.5235H69.0498V0.247437Z"
        fill="#5D6293"
      />
      <path
        d="M83.2617 0.247437H84.8237V6.59019H92.8077V0.247437H94.3697V14.5235H92.8077V8.09849H84.8237V14.5235H83.2617V0.247437Z"
        fill="#5D6293"
      />
      <path
        d="M117.58 12.4436L118.548 11.2589C119.167 11.9065 119.907 12.4189 120.722 12.7656C121.538 13.1123 122.412 13.2862 123.294 13.277C125.15 13.277 126.378 12.2575 126.378 10.853V10.8112C126.378 9.48225 125.711 8.74529 122.78 8.09799C119.596 7.40955 118.107 6.32807 118.107 3.96612V3.92497C118.107 1.68707 120.023 0.0338329 122.654 0.0338329C124.429 -0.01646 126.16 0.605758 127.52 1.78288L126.606 3.02957C125.497 2.029 124.074 1.47831 122.601 1.48011C120.805 1.48011 119.657 2.49957 119.657 3.7874V3.82855C119.657 5.17166 120.365 5.89449 123.415 6.58293C126.465 7.27137 127.922 8.44191 127.922 10.6184V10.6577C127.922 13.1093 125.919 14.6999 123.196 14.6999C121.095 14.7231 119.067 13.9033 117.541 12.4135"
        fill="#5D6293"
      />
      <path
        d="M136.772 1.74162H132.129V0.247437H143.003V1.74162H138.36V14.5235H136.777L136.772 1.74162Z"
        fill="#5D6293"
      />
      <path
        d="M112.093 9.01239C111.86 9.68369 111.546 10.3222 111.159 10.9131C110.818 11.4708 110.11 12.5455 109.009 14.1846L106.799 17.4487H106.699C106.125 16.5809 105.364 15.5001 104.49 14.1846C103.382 12.5455 102.681 11.4714 102.334 10.9131C101.949 10.3272 101.633 9.69612 101.392 9.03327C101.302 8.72599 101.242 8.40989 101.214 8.08997H99.7303C99.7649 8.57005 99.8547 9.0442 99.9976 9.50247C100.267 10.2921 100.635 11.0421 101.092 11.7336C101.446 12.3054 102.167 13.3918 103.288 15.0597C104.178 16.3699 104.91 17.4534 105.484 18.3103C105.624 18.5242 105.813 18.6989 106.035 18.8191C106.256 18.9392 106.503 19.0009 106.753 18.9988C107.002 19.0016 107.248 18.94 107.468 18.8198C107.689 18.6996 107.876 18.5245 108.014 18.3103L110.217 15.0554C111.332 13.3973 112.053 12.3011 112.406 11.7428C112.857 11.0441 113.227 10.293 113.508 9.50492C113.647 9.04593 113.734 8.57198 113.768 8.09242H112.293C112.258 8.41205 112.196 8.72778 112.106 9.03573"
        fill="#00B48D"
      />
      <path
        d="M101.906 4.4592C102.403 3.58952 103.106 2.86443 103.949 2.35211C104.794 1.84276 105.756 1.57435 106.735 1.57435C107.715 1.57435 108.676 1.84276 109.522 2.35211C110.364 2.86599 111.067 3.59072 111.565 4.4592C111.916 5.09638 112.142 5.79819 112.232 6.52513H113.707C113.603 5.52573 113.294 4.56072 112.799 3.6946C112.177 2.60231 111.295 1.69206 110.236 1.05015C109.163 0.405531 107.943 0.065918 106.701 0.065918C105.46 0.065918 104.24 0.405531 103.167 1.05015C102.109 1.6938 101.228 2.60366 100.604 3.6946C100.108 4.56024 99.7986 5.52548 99.6958 6.52513H101.177C101.26 5.79646 101.487 5.09315 101.845 4.4592"
        fill="#5D6293"
      />
      <path
        d="M109.65 6.52082C109.475 5.8618 109.095 5.28031 108.568 4.86582C108.042 4.45133 107.398 4.22681 106.736 4.22681C106.073 4.22681 105.429 4.45133 104.903 4.86582C104.376 5.28031 103.997 5.8618 103.822 6.52082C103.75 6.78537 103.711 7.05852 103.708 7.33331C103.71 7.58159 103.739 7.82886 103.795 8.07027C103.959 8.747 104.337 9.34782 104.87 9.77718C105.403 10.2065 106.059 10.4398 106.735 10.4398C107.411 10.4398 108.068 10.2065 108.601 9.77718C109.134 9.34782 109.512 8.747 109.676 8.07027C109.729 7.82832 109.758 7.58138 109.763 7.33331C109.759 7.05852 109.721 6.78537 109.649 6.52082M107.821 8.47068C107.678 8.61955 107.507 8.7378 107.319 8.81868C107.132 8.89956 106.931 8.94149 106.727 8.94206C106.524 8.94263 106.322 8.90183 106.134 8.82201C105.946 8.74218 105.775 8.62488 105.631 8.47682L105.625 8.47068C105.514 8.35444 105.419 8.22227 105.345 8.07825C105.224 7.83756 105.164 7.56919 105.171 7.29823C105.178 7.02726 105.251 6.76254 105.384 6.5288C105.455 6.41368 105.538 6.30734 105.631 6.21191C105.771 6.05704 105.94 5.93455 106.129 5.85259C106.318 5.77064 106.521 5.73108 106.726 5.73657C106.931 5.73233 107.134 5.77245 107.322 5.85433C107.511 5.93621 107.681 6.05803 107.821 6.21191C107.911 6.30767 107.989 6.41404 108.054 6.5288C108.203 6.77171 108.279 7.05411 108.275 7.3413C108.277 7.59808 108.217 7.85146 108.101 8.07825C108.025 8.22346 107.93 8.35768 107.82 8.47744"
        fill="#00B48D"
      />
    </g>
    <defs>
      <clipPath id="clip0_1729_7625">
        <rect width="143" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
