import classes from "./RatingPage.module.scss";
import { FullPageContainer } from '../../common/FullPageContainer/FullPageContainer';
import { RatingHighlights } from './components/RatingHighlights/RatingHighlights';
import { ReservationsHighlights } from './components/ReservationsHighlights/ReservationsHighlights';

export const RatingPage = () => {
  return <FullPageContainer className={classes.ratingContainer}>
    <>
      <RatingHighlights />
      <ReservationsHighlights />
    </>
  </FullPageContainer>;
}