export const LogoutIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66667 24C1.93334 24 1.30534 23.7387 0.782669 23.216C0.260002 22.6933 -0.000886625 22.0658 2.26372e-06 21.3333V2.66667C2.26372e-06 1.93334 0.261336 1.30534 0.784002 0.782669C1.30667 0.260002 1.93422 -0.000886625 2.66667 2.26372e-06H12V2.66667H2.66667V21.3333H12V24H2.66667ZM17.3333 18.6667L15.5 16.7333L18.9 13.3333H8V10.6667H18.9L15.5 7.26667L17.3333 5.33333L24 12L17.3333 18.6667Z"
      fill="#00B48D"
    />
  </svg>
);
