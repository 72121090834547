import { createErrorSelector, createLoadingSelector } from "../../utils/reduxUtils";
import { GET_RESERVATIONS_DATA } from "../actions";

export const allReservations = (state: any) => state.reservations.reservations;

export const previousReservationsSelector = (state: any) => state.reservations.reservationsMap.previous;

export const previousHighlightsSelector = (state: any) => state.reservations.reservationsMap.previousHighlightsData;

export const upcomingReservationsSelector = (state: any) => state.reservations.reservationsMap.upcoming;

export const upcomingHighlightsSelector = (state: any) => state.reservations.reservationsMap.upcomingHighlightsData;

export const reservationsLoadingSelector = createLoadingSelector([GET_RESERVATIONS_DATA]);
export const reservationsErrorSelector = createErrorSelector([GET_RESERVATIONS_DATA]);
