import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import classes from "./404ErrorPage.module.scss";
import {Button, CircularProgress} from "@mui/material";
import { useNavigate } from "react-router";

const NotFoundImage = () => (
  <svg
    width="305"
    height="120"
    viewBox="0 0 305 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.34 99.2V77.12L50.9 4.8H81.46V75.52H94.58V99.2H81.46V120H54.1V99.2H0.34ZM56.02 34.88L29.14 75.52H56.02V34.88ZM106.381 60C106.381 41.6533 109.901 27.2 116.941 16.64C124.088 6.07999 135.555 0.79999 151.341 0.79999C167.128 0.79999 178.541 6.07999 185.581 16.64C192.728 27.2 196.301 41.6533 196.301 60C196.301 78.56 192.728 93.12 185.581 103.68C178.541 114.24 167.128 119.52 151.341 119.52C135.555 119.52 124.088 114.24 116.941 103.68C109.901 93.12 106.381 78.56 106.381 60ZM169.421 60C169.421 49.2267 168.248 40.96 165.901 35.2C163.555 29.3333 158.701 26.4 151.341 26.4C143.981 26.4 139.128 29.3333 136.781 35.2C134.435 40.96 133.261 49.2267 133.261 60C133.261 67.2533 133.688 73.28 134.541 78.08C135.395 82.7733 137.101 86.6133 139.661 89.6C142.328 92.48 146.221 93.92 151.341 93.92C156.461 93.92 160.301 92.48 162.861 89.6C165.528 86.6133 167.288 82.7733 168.141 78.08C168.995 73.28 169.421 67.2533 169.421 60ZM209.796 99.2V77.12L260.356 4.8H290.916V75.52H304.036V99.2H290.916V120H263.556V99.2H209.796ZM265.476 34.88L238.596 75.52H265.476V34.88Z"
      fill="#F5F5FC"
    />
  </svg>
);

export const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <FullPageContainer className={classes.notFoundPageWrapper}>
      <div className={classes.notFoundPageContainer}>
        <div className={classes.notFoundImage}>
          <NotFoundImage />
        </div>
        <h4>Page not found</h4>
        <p>The page you are looking for doesn’t exist or has been moved.</p>
        <Button
          color="primary"
          onClick={() => navigate(-1)}
          size="large"
          variant="contained"
        >
          Go Back
        </Button>
      </div>
    </FullPageContainer>
  );
};
