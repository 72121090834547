import classes from "./ReservationsPage.module.scss";
import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import { ReservationsTable } from "./ReservationsTable/ReservationsTable";

export const CalendarReservationsPage = () => {
  return <FullPageContainer className={classes.reservationsContainer}>
    <>
      <ReservationsTable />
    </>
  </FullPageContainer>;
};
