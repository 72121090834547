import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import { OccupancyRateBarLineCharts } from "../../common/OccupancyRateCharts/OccupancyRateBarLineCharts";
import { PerfomanceHighlights } from "./components/PerfomanceHighlights";

export const PerfomancePage = () => {
  return (
    <FullPageContainer>
      <>
        <PerfomanceHighlights/>
        <OccupancyRateBarLineCharts page="performance" bothCharts={false} />
      </>
    </FullPageContainer>
  );
}
