import { call, put, takeLatest } from "redux-saga/effects";
import { OccupancyRateAPI } from "../../../api/occupancyRateAPI";
import {
  GENERAL_OCCUPANCY_ERROR,
  GENERAL_OCCUPANCY_REQUEST,
  GENERAL_OCCUPANCY_SUCCESS,
} from "../../actions";

function* workerGeneralOccupancy(action: {
  type: string;
  payload: { param: string };
}): Generator<any> {
  try {
    const rate: any = yield call(OccupancyRateAPI.getOccupancy, action.payload);
    yield put({
      type: GENERAL_OCCUPANCY_SUCCESS,
      payload: { generalOccupancy: { [action.payload.param]: rate } }
    });
  } catch (error: any) {
    yield put({
      type: GENERAL_OCCUPANCY_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherGeneralOccupancy() {
  yield takeLatest(GENERAL_OCCUPANCY_REQUEST, workerGeneralOccupancy);
}