import { call, put, takeLatest } from "redux-saga/effects";
import { OccupancyRateAPI } from "../../../api/occupancyRateAPI";
import { processDailyOccupancy } from "../../../utils/dataProcessingUtils";
import {
  DAILY_OCCUPANCY_REQUEST,
  DAILY_OCCUPANCY_SUCCESS,
  DAILY_OCCUPANCY_ERROR
} from "../../actions";

function* workerDailyOccupancy(action: {
  type: string;
  payload: { param: string };
}): Generator<any> {
  try {
    const dailyOccupancy: any = yield call(OccupancyRateAPI.getDailyOccupancyRate, action.payload);
    yield put({
      type: DAILY_OCCUPANCY_SUCCESS,
      payload: { dailyOccupancy: { [action.payload.param]: processDailyOccupancy(dailyOccupancy) } }
    });
  } catch (error: any) {
    yield put({
      type: DAILY_OCCUPANCY_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherDailyOccupancy() {
  yield takeLatest(DAILY_OCCUPANCY_REQUEST, workerDailyOccupancy);
}
