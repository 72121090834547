import { IIcon } from "../../views/interfaces";

export const InstagramIcon = ({ className }: IIcon) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.84 12C15.84 12.7595 15.6148 13.5019 15.1928 14.1334C14.7709 14.7649 14.1712 15.2571 13.4695 15.5477C12.7678 15.8383 11.9957 15.9144 11.2509 15.7662C10.506 15.618 9.82174 15.2523 9.28471 14.7153C8.74768 14.1783 8.38195 13.494 8.23378 12.7491C8.08562 12.0043 8.16166 11.2322 8.4523 10.5305C8.74294 9.82883 9.23513 9.2291 9.86661 8.80716C10.4981 8.38521 11.2405 8.16 12 8.16C13.0175 8.16316 13.9923 8.56875 14.7118 9.28821C15.4312 10.0077 15.8368 10.9825 15.84 12ZM24 6.72V17.28C24 19.0623 23.292 20.7715 22.0318 22.0318C20.7715 23.292 19.0623 24 17.28 24H6.72C4.93775 24 3.22849 23.292 1.96824 22.0318C0.707998 20.7715 0 19.0623 0 17.28V6.72C0 4.93775 0.707998 3.22849 1.96824 1.96824C3.22849 0.707998 4.93775 0 6.72 0H17.28C19.0623 0 20.7715 0.707998 22.0318 1.96824C23.292 3.22849 24 4.93775 24 6.72ZM17.76 12C17.76 10.8608 17.4222 9.74714 16.7893 8.79992C16.1563 7.85269 15.2568 7.11441 14.2043 6.67845C13.1518 6.24249 11.9936 6.12843 10.8763 6.35068C9.75895 6.57293 8.73262 7.12151 7.92706 7.92706C7.12151 8.73262 6.57293 9.75895 6.35068 10.8763C6.12843 11.9936 6.24249 13.1518 6.67845 14.2043C7.11441 15.2568 7.85269 16.1563 8.79992 16.7893C9.74714 17.4222 10.8608 17.76 12 17.76C13.5276 17.76 14.9927 17.1531 16.0729 16.0729C17.1531 14.9927 17.76 13.5276 17.76 12ZM19.68 5.76C19.68 5.47519 19.5955 5.19679 19.4373 4.95998C19.2791 4.72317 19.0542 4.5386 18.7911 4.42961C18.5279 4.32062 18.2384 4.29211 17.9591 4.34767C17.6797 4.40323 17.4232 4.54038 17.2218 4.74177C17.0204 4.94315 16.8832 5.19974 16.8277 5.47907C16.7721 5.7584 16.8006 6.04794 16.9096 6.31106C17.0186 6.57419 17.2032 6.79909 17.44 6.95732C17.6768 7.11555 17.9552 7.2 18.24 7.2C18.6219 7.2 18.9882 7.04829 19.2582 6.77823C19.5283 6.50818 19.68 6.14191 19.68 5.76Z"
      fill="#B4B7CF"
    />
  </svg>
);
