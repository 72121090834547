import { all, call, put, takeLatest } from "redux-saga/effects";
import { OccupancyRateAPI } from "../../../api/occupancyRateAPI";
import {
  MONTHLY_OCCUPANCY_RATE_ERROR,
  MONTHLY_OCCUPANCY_RATE_REQUEST,
  MONTHLY_OCCUPANCY_RATE_SUCCESS
} from "../../actions";

function* workerMonthlyOccupancy(action: {
  type: string;
  payload: { year: string };
}): Generator<any> {
  try {
    const year = action.payload.year;
    const data: any = yield all([
      call(OccupancyRateAPI.getYearlyOccupancyRate, action.payload),
      call(OccupancyRateAPI.getYearlyMarketOccupancyRate, action.payload)
    ]);
    const [yearlyOccupancy, yearlyMarketOccupancy] = data;
    yield put({
      type: MONTHLY_OCCUPANCY_RATE_SUCCESS,
      payload: {
        yearlyMarketOccupancy: { [year]: yearlyMarketOccupancy },
        yearlyOccupancy: { [year]: yearlyOccupancy }
      }
    });
  } catch (error: any) {
    yield put({
      type: MONTHLY_OCCUPANCY_RATE_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherMonthlyOccupancy() {
  yield takeLatest(MONTHLY_OCCUPANCY_RATE_REQUEST, workerMonthlyOccupancy);
}
