export const BarIcon = () => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9995 18H-0.000488281V0H2.19951V16H4.39951V7H8.79951V16H10.9995V3H15.3995V16H17.5995V11H21.9995V18Z"
      fill="#B4B7CF"
    />
  </svg>
);
