export const VrboIcon = () => (
  <svg
    width="30"
    height="28"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7188 23.3333L14.4444 21.7701L11.1791 5.72414C10.5669 2.5977 10.839 0.988506 11.8367 0.0689662V0.0229899H10.068C9.52381 1.1954 9.36508 2.34483 9.97732 5.42529L13.7188 23.3333ZM12.2222 26.4368L12.9478 24.8966L8.86621 5.37931C8.14059 2.34483 8.322 0.965517 8.5941 0H7.02948C7.0068 1.63218 7.21088 3.28736 7.64172 4.85058L12.2222 26.4368ZM5.62358 0H3.78685V0.0459798C4.60317 1.05747 4.85261 2.27586 5.48753 5.01149L10.3855 28H11.4966L6.62131 4.94253C6.19048 3.31034 5.87302 1.67816 5.62358 0ZM1.97279 0H0V0.0459798C1.79138 0.758623 2.67574 2.13793 3.37868 5.37931L8.20862 28H9.31973L4.44444 5.24138C3.60544 1.28736 2.72109 0.574713 1.97279 0ZM26.9614 0C24.6485 1.37931 23.2653 3.90805 20.3855 10.2069L20.4535 10.2529C23.7868 3.93104 25.6916 1.4023 30 0.0919561V0.0229899H26.9614V0ZM20.2268 10.092L20.2948 10.1379C23.0385 3.6092 23.7415 1.77012 24.8753 0.0689662V0.0229899H22.6531V0.0919561C22.8798 2.68966 22.0181 5.10345 20.2268 10.092Z"
      fill="#5D6293"
    />
  </svg>
);
