import { all, call, put, takeLatest } from "redux-saga/effects";
import { L3060D, L30D } from '../../../utils/constants/filterConstants';
import { CommonAPI } from "../../../api/commonAPI";
import { RevenueAPI } from "../../../api/revenueAPI";
import {
  GET_REVENUE_SUCCESS,
  GET_REVENUE_ERROR,
  PERFOMANCE_HIGHLIGHTS_SUCCESS,
  GET_REVENUE_REQUEST
} from "../../actions";

function* workerRevenue(action: {
  type: string;
  payload: { param: string };
}): Generator<any> {
  try {
    const params = action.payload;
    const data: any = yield all([
      call(CommonAPI.getNetReservationIncome, params),
      call(RevenueAPI.getTotalRevenue),
    ]);
    const [netReservationIncome, totalRevenue] = data;

    const basePayload: {[key: string] : object} = {
      [params.param]: {
        netReservationIncome: netReservationIncome?.Total_income
      }
    }

    if (params.param === L30D) {
      basePayload[L3060D] = {
        netReservationIncome: netReservationIncome?.last_30_60_day_total_income,
      }
    }

    yield put({
      type: GET_REVENUE_SUCCESS,
      payload: { totalRevenue }
    });
    yield put({
      type: PERFOMANCE_HIGHLIGHTS_SUCCESS,
      payload: { ...basePayload },
    });
  } catch (error: any) {
    yield put({
      type: GET_REVENUE_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherRevenue() {
  yield takeLatest(GET_REVENUE_REQUEST, workerRevenue);
}
