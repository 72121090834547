import { IIcon } from "../../views/interfaces";

export const LocationIcon = ({ className }: IIcon) => (
  <svg
    className={className}
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C7.34876 0.0028359 4.80697 0.99715 2.93225 2.7648C1.05754 4.53246 0.00300765 6.9291 0 9.42894C0 17.4971 9.09091 23.5938 9.47727 23.8509C9.63211 23.9481 9.81398 24 10 24C10.186 24 10.3679 23.9481 10.5227 23.8509C10.9091 23.5938 20 17.4971 20 9.42894C19.997 6.9291 18.9425 4.53246 17.0677 2.7648C15.193 0.99715 12.6512 0.0028359 10 0ZM10 6.00023C10.7192 6.00023 11.4223 6.20132 12.0203 6.57807C12.6183 6.95482 13.0843 7.49031 13.3596 8.11683C13.6348 8.74334 13.7068 9.43274 13.5665 10.0978C13.4262 10.7629 13.0799 11.3739 12.5713 11.8534C12.0627 12.3329 11.4148 12.6595 10.7094 12.7918C10.004 12.9241 9.27288 12.8562 8.60843 12.5966C7.94397 12.3371 7.37604 11.8977 6.97647 11.3338C6.57691 10.77 6.36364 10.1071 6.36364 9.42894C6.36364 8.51959 6.74675 7.64748 7.4287 7.00448C8.11065 6.36147 9.03558 6.00023 10 6.00023Z"
      fill="#5D6293"
    />
  </svg>
);
