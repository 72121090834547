import { combineReducers } from "redux";
import { userReducer } from "./reducers/userReducer";
import { appReducer } from "./reducers/appReducer";
import { errorReducer } from "./reducers/errorReducer";
import { loadingReducer } from "./reducers/loadingReducer";
import { perfomanceReducer } from "./reducers/perfomanceReducer";
import { occupancyReducer } from "./reducers/occupancyReducer";
import { revenueReducer } from "./reducers/revenueReducer";
import { reservationsHighlightsReducer } from "./reducers/reservationsHighlightsReducer";
import { reservationsIndicatorsReducer } from "./reducers/reservationsIndicatorsReducer"
import { reservationsPreviousIndicatorsReducer } from "./reducers/reservationsPreviousIndicatorsReducer";
import { ratingReducer } from "./reducers/ratingReducer";
import { reservationsReducer } from "./reducers/reservationsReducer";
import { documentsReducer } from "./reducers/documentsReducer";

export const rootReducer = combineReducers({
  user: userReducer,
  appStatus: appReducer,
  api: combineReducers({
    error: errorReducer,
    loading: loadingReducer
  }),
  perfomanceHighlights: perfomanceReducer,
  occupancy: occupancyReducer,
  revenue: revenueReducer,
  reservationsHighlights: reservationsHighlightsReducer,
  reservationsIndicators: reservationsIndicatorsReducer,
  reservationsPreviousIndicators: reservationsPreviousIndicatorsReducer,
  rating: ratingReducer,
  reservations: reservationsReducer,
  documents: documentsReducer,
});
