import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import classes from "./SettingsPage.module.scss";
import { SAVE_ACCOUNT_SETTINGS_REQUEST } from "../../../redux/actions";
import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import {
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput,
  TextField
} from "@mui/material";
import {
  saveSettingsErrorSelector,
  saveSettingsLoadingSelector
} from "../../../redux/selectors/userDataSelector";
import { Toast } from "../../common/Toast/Toast";
import { CalendarIcon } from "../../../assets/icons/CalendarIcon";
import {isMobileModeSelector} from "../../../redux/selectors/appStatusSelector";

export const SettingsPage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(saveSettingsLoadingSelector);
  const isError = useSelector(saveSettingsErrorSelector);
  const isMobileView = useSelector(isMobileModeSelector);

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nationality, setNationality] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    dispatch({
      type: SAVE_ACCOUNT_SETTINGS_REQUEST,
      payload: {
        name,
        last_name: surname,
        email,
        phone,
        nationality,
        date_of_birth: dateOfBirth
      }
    });
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted && !isLoading && !isError) {
      setName("");
      setSurname("");
      setEmail("");
      setPhone("");
      setNationality("");
      setDateOfBirth(null);
      setTimeout(() => {
        setSubmitted(false);
      }, 4000);
    }
  }, [isError, isLoading, submitted]);

  return (
    <FullPageContainer>
      <>
        {submitted && !isLoading && !isError && (
          <Toast
            className={classes.toast}
            successMessage="Account information successfully saved"
            type="success"
          />
        )}
        {submitted && !isLoading && isError && (
          <Toast
            className={classes.toast}
            errorMessage={isError}
            type="error"
          />
        )}
        <div className={classes.settingsContainer}>
          <h4>Account information</h4>
          <div className={classes.contactForm}>
            <FormControl className={classes.formControl}>
              <p className={classes.label}>Name</p>
              <OutlinedInput
                className={classes.input}
                id="name"
                name="name"
                onChange={event => setName(event.target.value)}
                placeholder="Name"
                type="text"
                value={name}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <p className={classes.label}>Last Name</p>
              <OutlinedInput
                className={classes.input}
                id="surname"
                name="surname"
                onChange={event => setSurname(event.target.value)}
                placeholder="Last Name"
                type="text"
                value={surname}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <>
                <p className={classes.label}>Date of birth</p>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="MM/dd/yyyy"
                    InputAdornmentProps={{
                      children: <CalendarIcon />
                    }}
                    value={dateOfBirth}
                    onChange={value => setDateOfBirth(value)}
                    renderInput={params => (
                      <TextField
                        className={classes.input}
                        id="date-of-birth"
                        name="dateOfBirth"
                        placeholder="MM/DD/YYYY"
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            </FormControl>
            <FormControl className={classes.formControl}>
              <p className={classes.label}>Nationality</p>
              <OutlinedInput
                className={classes.input}
                id="nationality"
                name="nationality"
                onChange={event => setNationality(event.target.value)}
                placeholder="Nationality"
                type="text"
                value={nationality}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <p className={classes.label}>Email</p>
              <OutlinedInput
                id="email"
                name="email"
                onChange={event => setEmail(event.target.value)}
                placeholder="Email"
                type="email"
                value={email}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <p className={classes.label}>Phone Number</p>
              <OutlinedInput
                id="phone-number"
                name="phoneNumber"
                onChange={event => setPhone(event.target.value)}
                placeholder="Phone Number"
                type="phone"
                value={phone}
              />
            </FormControl>
            <div className={classes.actionFormControl}>
              <Button
                className={classes.action}
                sx={{
                  borderRadius: isMobileView ? '24px' : '16px',
                  height: isMobileView ? '62px' : '56px',
                  minWidth: isMobileView ? '100%' : '208px',
                  marginTop: isMobileView ? '16px' : 0,
                }}
                color="primary"
                onClick={handleSubmit}
                size="small"
                variant="contained"
              >
                {submitted && isLoading ? (
                  <CircularProgress classes={{ root: classes.loader }} />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>
        </div>
      </>
    </FullPageContainer>
  );
};
