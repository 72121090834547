import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import classes from "./RevenueTable.module.scss";
import { GET_YEARLY_REVENUE_REQUEST } from "../../../../../redux/actions";
import { yearlyRevenueSelector } from "../../../../../redux/selectors/revenueSelector";
import { getFormattedCurrency } from '../../../../../utils/commonUtils';
import {isMobileModeSelector} from "../../../../../redux/selectors/appStatusSelector";

interface IRow {
  start_date: string;
  reservations: number;
  revenue_usd: number;
}

export const RevenueTable = () => {
  const dispatch = useDispatch();
  const rows = useSelector(yearlyRevenueSelector);
  const isMobileView = useSelector(isMobileModeSelector);

  useEffect(() => {
    dispatch({
      type: GET_YEARLY_REVENUE_REQUEST
    });
  }, [dispatch]);

  return (
    <div className={classes.tableContainer}>
      <h4 className={classes.header}>Revenue by Year</h4>
      <div className={classes.tableWrapper}>
        <Table size="small" aria-label="Revenue table" className={classes.table}>
          <TableHead className={classes.tableHead} style={{ tableLayout: 'fixed'}}>
            <TableRow>
              <TableCell align="center">Year</TableCell>
              <TableCell align="center">Reservations</TableCell>
              <TableCell align="center">Revenue</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody} >
            {rows && rows.map(
              (
                { start_date, reservations, revenue_usd }: IRow,
                index: number
              ) => {
                const year = new Date(start_date).getFullYear();
                return (
                  <TableRow className={classes.tableRow} key={index} >
                    <TableCell align="center">{year}</TableCell>
                    <TableCell align="center">{reservations}</TableCell>
                    <TableCell align="center">
                      {getFormattedCurrency(revenue_usd)}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
