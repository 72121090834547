export const InfoIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 18C12.34 18 12.6252 17.8848 12.8556 17.6544C13.0852 17.4248 13.2 17.14 13.2 16.8V11.97C13.2 11.63 13.0852 11.35 12.8556 11.13C12.6252 10.91 12.34 10.8 12 10.8C11.66 10.8 11.3752 10.9148 11.1456 11.1444C10.9152 11.3748 10.8 11.66 10.8 12V16.83C10.8 17.17 10.9152 17.45 11.1456 17.67C11.3752 17.89 11.66 18 12 18ZM12 8.4C12.34 8.4 12.6252 8.2848 12.8556 8.0544C13.0852 7.8248 13.2 7.54 13.2 7.2C13.2 6.86 13.0852 6.5748 12.8556 6.3444C12.6252 6.1148 12.34 6 12 6C11.66 6 11.3752 6.1148 11.1456 6.3444C10.9152 6.5748 10.8 6.86 10.8 7.2C10.8 7.54 10.9152 7.8248 11.1456 8.0544C11.3752 8.2848 11.66 8.4 12 8.4ZM12 24C10.34 24 8.78 23.6848 7.32 23.0544C5.86 22.4248 4.59 21.57 3.51 20.49C2.43 19.41 1.5752 18.14 0.9456 16.68C0.3152 15.22 0 13.66 0 12C0 10.34 0.3152 8.78 0.9456 7.32C1.5752 5.86 2.43 4.59 3.51 3.51C4.59 2.43 5.86 1.5748 7.32 0.9444C8.78 0.3148 10.34 0 12 0C13.66 0 15.22 0.3148 16.68 0.9444C18.14 1.5748 19.41 2.43 20.49 3.51C21.57 4.59 22.4248 5.86 23.0544 7.32C23.6848 8.78 24 10.34 24 12C24 13.66 23.6848 15.22 23.0544 16.68C22.4248 18.14 21.57 19.41 20.49 20.49C19.41 21.57 18.14 22.4248 16.68 23.0544C15.22 23.6848 13.66 24 12 24Z"
      fill="#8FB6E2"
    />
  </svg>
);
